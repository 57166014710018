import React, { useState, useEffect } from 'react';
import { RxAvatar } from "react-icons/rx";
import { Link } from "react-router-dom";


import { RiMenu3Line, RiCloseLine } from 'react-icons/ri';

import {Auth} from 'aws-amplify' ;

import './navbar.css';

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [isAuthorized, setIsAuthorized] = useState(false);

// https://github.com/aws-amplify/amplify-js/issues/4927

  useEffect(() => {
    async function getCurrentUser() {
      try {
        await Auth.currentAuthenticatedUser();
        setIsAuthorized(true);
      } catch (error) {
        setIsAuthorized(false);
      }
    }
    getCurrentUser();
  }, []);

  type Props = {
    className?: string;
    id?: string;
  }

  const MenuItems: React.FC<Props> = (props) => (
  <div id={props?.id} className={props?.className}>
    <a className="log__navbar-link" href="/#intro">Home</a>
    {
      isAuthorized &&
        <Link className="log__navbar-link" to={`/projects`}>Projects</Link>
    }
    <a className="log__navbar-link" href="https://doc.2minlog.com/quick-start-guide/">Quick-start</a>
    <a className="log__navbar-link" href="https://doc.2minlog.com/">Documentation</a>
    <a className="log__navbar-link" href="https://doc.2minlog.com/pricing/">Pricing</a>
    {
      isAuthorized ?
        <Link className="log__navbar-link" to={'/user'}><RxAvatar/></Link> :
        <Link className="log__navbar-link" to={'/user'}>Login</Link>
    }
  </div>
  ) ;

  function delay(time: number) {
    return new Promise(resolve => setTimeout(resolve, time));
  }

  function ani() {
    var dd = document.getElementById('id-log__navbar-menu-disappear'); 
    if(dd) {
      dd.setAttribute('class', "log__navbar log__navbar-menu log__navbar-menu-disappear");
    }
  }

  return (
    <div>
      <MenuItems className="log__navbar"/>
      <div className="log__navbar log__navbar-icon">
        {toggleMenu
            ? <div className="log__navbar-icon-anime"><RiCloseLine className="log__navbar-icon-anime" onClick={() => { 
              ani();
              delay(300).then(() => setToggleMenu(false));
              }
            } /></div>
            : <div className="log__navbar-icon-anime"><RiMenu3Line onClick={() => setToggleMenu(true)} /></div>
          }
      </div>
        {toggleMenu && (
          <MenuItems id='id-log__navbar-menu-disappear' className="log__navbar log__navbar-menu"/>
        )}
    </div>
  ) ;
};

export default Navbar;
