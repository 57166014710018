import React, { ReactNode, MouseEventHandler } from 'react';
import './ctabtn.css';

interface CTAbtnProps {
  children: ReactNode;
  className?: string;
  onClick?: MouseEventHandler<HTMLDivElement>;
}

const CTAbtn: React.FC<CTAbtnProps> = ({ children, className, onClick }) => (
  <div className={`log__CTAbtn ${className}`} onClick={onClick}>
    {children}
  </div>
);


export default CTAbtn;