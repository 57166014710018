import React from 'react';
import './footer.css';

import {
  TfiSettings, TfiClose, TfiPlus,
  TfiTwitter, TfiFacebook, TfiLink, TfiLinkedin
} from "react-icons/tfi";


const Footer = () => (
  <div id="about" className="log__footer">
    <a className="log__footer-link" href="https://x.com/2minlog"><TfiTwitter/></a>
    <a className="log__footer-link" href="https://www.facebook.com/profile.php?id=61560538064690"><TfiFacebook/></a>
    <a className="log__footer-link" href="https://doc.2minlog.com/conditions/">Privacy policy</a>
    <a className="log__footer-link" href="https://doc.2minlog.com/conditions/">Terms of service</a>
    <a className="log__footer-link" href="https://doc.2minlog.com/">Use cases</a>
    <a className="log__footer-link" href="https://doc.2minlog.com/contact/">Contact</a>   
  </div>
);

export default Footer;