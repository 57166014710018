import './graphcard.css';
// import { useState, useEffect, useRef } from 'react';
// import { GraphDetail } from './../../pages';

import React from 'react';

// https://www.npmjs.com/package/@monaco-editor/react
// https://github.com/suren-atoyan/monaco-react#readme
import Editor from "@monaco-editor/react";

// import './graphdetail.css';
import { CTAbtn, ImageLoader } from '../../components';
import {
  TfiSettings, TfiClose, TfiPlus,
  TfiTwitter, TfiFacebook, TfiLink, TfiLinkedin
} from "react-icons/tfi";
import { useState, useEffect, useRef } from 'react';
import {
  renameGraph, setGraphDefaultSources,
  deleteGraph, resetGraphPublic, getGraph, setGraphCode,
  getFreeImageKey
} from '../../middleware/apollo';
import { CopyString } from './../../components';

import { updateInfoBarInfo } from '../../pages'
import { defaultCode, getImgURL, initialMaxRecordsDownloads, initialSecondsBack } from '../../config'

import { createNewGraph, duplicateGraph, setMaxRecordsDownloadsAndSecondsBack } from '../../middleware/apollo';

{/* https://api.2minlog.com/img?filename_extension=jpg&publicID=PUB-5a25e4d8-b474-46e5-b7a5-06c4e420eb23,PUB-64ae8d34-b910-4c47-a03a-bac6c79c091d */ }

interface Graph {
  graphID: string;
  time: string;
  email: string;
  graphPublic: string;
  graphName: string;
  payLoad: string;
}

type GraphCardProps = {
  graph: Graph;
  projectList: Project[];
  runSetGraphList: () => void;
};

interface Project {
  projectID: string;
  projectPublic: string;
  name: string;
  email: string;
  payLoad: string | null;
  time: string;
  projectSecret: string;
}

async function getFileContent(url: string): Promise<string> {
  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`HTTP error: ${response.status}`);
    }
    const text = await response.text();
    return text;
  } catch (error) {
    console.error('Error fetching file:', error);
    throw error; // Re-throw to propagate the error
  }
}

interface GraphElementProps {
  graphPublic: string;
}

let rerenderHack: any = undefined ;

const GraphElement: React.FC<GraphElementProps> = ({ graphPublic }) => {
  const [key, setKey] = useState((new Date()).getTime());
  const [freeImgkey, setFreeImgkey] = useState("");

  rerenderHack = setKey ;
  
  useEffect(() => {
    const runGetFreeImageKey = async () => {
      const imgkey1 = await getFreeImageKey() ;
      setFreeImgkey(imgkey1);
    } ;
    runGetFreeImageKey() ;
  }
  ,[]);

  if(freeImgkey=="")
    return (<></>);

  return (
    <div className='log__graphdetail--graph-element'>
      <a 
        href={getImgURL+"?graphPublic="+graphPublic}
        target="_blank" 
        rel="noreferrer">
        <ImageLoader
          src={getImgURL+"?graphPublic="+graphPublic+"&key="+key+"&freeImgKey="+freeImgkey}
          alt="Graph"
          key={"img_" + key}
        />
      </a>
      {/* https://github.com/suren-atoyan/monaco-react */}
      <CTAbtn
        className='log__graphdetail--btn log__graphdetail--btn--center'
        onClick={() => { setKey((new Date()).getTime()) }}>
        <p>Refresh graph</p>
      </CTAbtn>
    </div>
  );
}

const projectIDListtoPublic = (defaultDataSourceRef: string[], projectList: Project[]): string[] => {
  return defaultDataSourceRef.map(projectID => {
    const project = projectList.find(p => p.projectID === projectID);
    return project ? project.projectPublic : undefined;
  }).filter((projectPublic): projectPublic is string => projectPublic !== undefined) as string[];
}  

/////////////////////////////////////////////////////////////////////////////////////////
//// Parsing time

type TimeResult = {
  Y: number,
  M: number,
  D: number,
  h: number,
  m: number,
  s: number
};

function parseStringToSecond(timeString: string): number {
  // Regular expression to match the pattern [h,m,s,D,M,Y]
  // It looks for numbers followed by a specific letter (D, h, m, s)
  // or numbers without a unit (treated as seconds)
  const regex = /(\d+(?:\.\d+)?)([YMDhm])?/g;
  const regex1 = /(\d+(?:\.\d+)?)([YMDhms ])?/g;
  let match: RegExpExecArray | null;
  let result: TimeResult = { Y: 0, M: 0, D: 0, h: 0, m: 0, s: 0 };

  while ((match = regex.exec(timeString)) !== null) {
    const value = parseFloat(match[1]);
    let unit: string = match[2] || 's'; // Default to 's' if no unit is provided
    if (unit in result) {
      result[unit as keyof TimeResult] = value;
    }
  }

  const r =  result ;
  if (timeString.replace(regex1, '').trim().length > 0) {
    console.log('timeString.replace(regex1, "").trim()', timeString.replace(regex1, '').trim().length, timeString.replace(regex1, '').trim())
    return 0 ;
  }


  return ((( r.Y * 365 + r.M * 31 + r.D ) * 24 + r.h ) * 60 + r.m) * 60 + r.s ;
}

const convertSecondsToFormattedString = (timeInSeconds: number): string => {
  const secondsPerMinute = 60;
  const minutesPerHour = 60;
  const hoursPerDay = 24;
  const daysPerMonth = 31; // 31 days per month
  const daysPerYear = 365; // 365 days per year

  const seconds = timeInSeconds % secondsPerMinute;
  const totalMinutes = Math.floor(timeInSeconds / secondsPerMinute);
  const minutes = totalMinutes % minutesPerHour;
  const totalHours = Math.floor(totalMinutes / minutesPerHour);
  const hours = totalHours % hoursPerDay;
  const totalDays = Math.floor(totalHours / hoursPerDay);

  const years = Math.floor(totalDays / daysPerYear);
  const daysAfterYears = totalDays - years * daysPerYear;

  const months = Math.floor(daysAfterYears / daysPerMonth);
  const days = daysAfterYears - months * daysPerMonth;

  let result: string[] = [];
  if (years > 0) result.push(`${years}Y`);
  if (months > 0) result.push(`${months}M`);
  if (days > 0) result.push(`${days}D`);
  if (hours > 0) result.push(`${hours}h`);
  if (minutes > 0) result.push(`${minutes}m`);
  if (seconds > 0) result.push(`${seconds}s`);

  return result.join(' ') || '0s'; // Return '0s' if all values are zero

};

/////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////
/// Main graph card function
const GraphCard: React.FC<GraphCardProps> = ({ graph, projectList, runSetGraphList }) => {
  const [displayThisCard, setDisplayThisCard] = useState(true);
  const [graphName, setGraphName] = useState(graph.graphName);
  const [graphPublic, setGraphPublic] = useState(graph.graphPublic);
  const [detailsAreVisible, setDetailsAreVisible] = useState(false);
  const [keyMainCard, setKeyMainCard] = useState(0);

  let initialDefaultDataSource: string[] = [];

  const [freeImgkey, setFreeImgkey] = useState("");

  useEffect(() => {
    const runGetFreeImageKey = async () => {
      const imgkey1 = await getFreeImageKey() ;
      setFreeImgkey(imgkey1);
    } ;
    runGetFreeImageKey() ;
  }
  ,[]);


  try {
    const json = JSON.parse(graph?.payLoad);
    initialDefaultDataSource = json?.default_source;
    // console.log("initialDefaultDataSource1", initialDefaultDataSource);
  } catch {
    initialDefaultDataSource = [];
    // console.log("initialDefaultDataSource2", initialDefaultDataSource);
  }

  const defaultDataSourceRef = useRef(initialDefaultDataSource);
  const publicDataSourceRef = useRef( projectIDListtoPublic(initialDefaultDataSource, projectList) );
  // console.log("XQ1",projectList);
  // console.log("XQ2",initialDefaultDataSource);
  const willRunSetGraphList = useRef(false);


  const setDetailsAreVisibleTrue = () => {
    setDetailsAreVisible(true);
  }

  /////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////
  /// Graph Detailed card
  const GraphDetail: React.FC = () => {
    const [displayConfirmDuplicatedGraph, setDisplayConfirmDuplicatedGraph] = useState(false);
    const [displayConfirmationDeleteGraph, setDisplayConfirmationDeleteGraph] = useState(false);
    const [code, setCode] = useState<string>('');
    const [isModified, setIsModified] = useState(false);
    const [displayConfirmationLosingChanges, setDisplayConfirmationLosingChanges] = useState(false);

    const [settingsAreVisible, setSettingsAreVisible] = useState(false);
    const [setDefaultDataSourceIsVisible, setSetDefaultDataSourceIsVisible] = useState(false);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [maxRecordsDownloadsString, setMaxRecordsDownloadsString] = useState("");
    const [secondsBackString, setSecondsBackString] = useState("");

    const setDetailsAreVisibleFalse1 = () => {
      if(isModified) {
        setDisplayConfirmationLosingChanges(true);
      } else {
        setDetailsAreVisibleFalse2() ;
      }
    }

    const setDetailsAreVisibleFalse2 = () => {
      if (willRunSetGraphList.current) {
        willRunSetGraphList.current = false;
        runSetGraphList();
      }
      setDetailsAreVisible(false);
      setKeyMainCard(new Date().getTime());
    }


    useEffect(
      () => {
        const getInitialCode = async () => {
          let initial_code = "";
          try {
            const graphHere = await getGraph(graph.graphID);
            // console.log("Initaial code1", graphHere) ;
            if (graphHere) {
              const payLoad = JSON.parse(graphHere.payLoad);
              initial_code = payLoad.code;
              // console.log("Initaial code2", initial_code) ;
            }
            if (initial_code === "") {
              initial_code = await getFileContent(defaultCode);
            }
          } catch (error) {
            console.error('Failed to load initial code:', error);
          } finally {
            setCode(initial_code);
          }
        }

        getInitialCode();
      }, []);

    const handleResetPublic = async () => {
      const ds = await resetGraphPublic(graph.graphID);
      setGraphPublic(ds?.graphPublic ?? "");
    };

    const handleBlurEditName = (name: string) => {
      if (name.length >= 3) {
        setGraphName(name);
        renameGraph(graph.graphID, name);
      }
    }

    const yesDeleteData = () => {
      deleteGraph(graph.graphID);
      setDisplayThisCard(false);
      // console.log('Deleting graph', graphName);
    }

    function handleEditorChange(value: any, event: any) {
      // console.log('here is the current model value:', value, event);
      setCode(value);
      setIsModified(true);
      // tmpCode.current = value;
      // if( value ) {
      //   setCode(value);
      // }

    }

    const handleDeployCode = async () => {
      // console.log("Deploy");
      // console.log("Code side", code.length);
      // console.log("Code", code);

      if( code ) {
        await setGraphCode(graph.graphID, code);
        //setKey(prevKey => prevKey + 1);
        // console.log("About to trigger rerenderHack", rerenderHack);
        if(rerenderHack) {
          // console.log('Rerendering the graph')
          rerenderHack(new Date().getTime()) ;  
        }
      
      }
      setIsModified(false);
      // updateInfoBarInfo('New version deployed.' );
    };


    const saveFile = () => {
      // Create a Blob from the string
      const blob = new Blob([code], { type: 'text/x-python' });
  
      // Create a URL for the blob
      const fileURL = URL.createObjectURL(blob);
  
      // Create a temporary anchor element and trigger a download
      const link = document.createElement('a');
      link.href = fileURL;
      link.download = graphName + '.py'; // Specify the file name
      document.body.appendChild(link); // Required for Firefox
      link.click();
  
      // Cleanup: remove the temporary element and revoke the blob URL
      document.body.removeChild(link);
      URL.revokeObjectURL(fileURL);
    };

    const handleDownloadCode = async () => {
      // console.log("Download", code);
      saveFile();
    };

    const handleUploadFile = () => {
      // console.log("Upload", code);
      // Check if `current` is not null before calling `click`
      if (fileInputRef.current !== null) {
        fileInputRef.current.click();
      }
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.files && event.target.files[0]) {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onload = (e: ProgressEvent<FileReader>) => {
          // Check if `e.target.result` is not null and is a string before setting state
          const result = e.target?.result;
          if (typeof result === 'string') {
            setCode(result);
          }
        };
        reader.readAsText(file);
      }
    };

// Handle ctrl-s
    useEffect(() => {
      // Define the function that handles the keydown event
      const handleKeyDown = (event: KeyboardEvent) => {
        // Check if Ctrl (or Cmd on Mac) is pressed along with 'S'
        if ((event.ctrlKey || event.metaKey) && event.key === 's') {
          event.preventDefault(); // Prevent the default save functionality
          
          // Execute your custom action here
          // console.log('Ctrl+S was pressed!');

          handleDeployCode();
        }
      };
      
      // Add the event listener
      document.addEventListener('keydown', handleKeyDown);
  
      // Remove the event listener on cleanup
      return () => {
        document.removeEventListener('keydown', handleKeyDown);
      };
    }, [code]); // The empty array means this effect runs only on mount and unmount
 

    useEffect(
      () => {
        const getInitialMaxRecordsDownloadsAndSecondsBack = async () => {
          let maxRecordsDownloads_ = initialMaxRecordsDownloads ;
          let secondsBack_ = initialSecondsBack ;

          try {
            const graphHere = await getGraph(graph.graphID);
            if (graphHere) {
              const payLoad = JSON.parse(graphHere.payLoad);

              if(payLoad.maxRecordsDownloads && payLoad.secondsBack) {
                maxRecordsDownloads_ = payLoad.maxRecordsDownloads;
                secondsBack_ = payLoad.secondsBack;
              }
            }

          } catch (error) {
            console.error('Failed to load initial MaxRecordsDownloadsAndSecondsBack', error);
          } finally {
            //console.log("maxRecordsDownloads_, secondsBack_", maxRecordsDownloads_, secondsBack_);
            setMaxRecordsDownloadsAndSecondsBack(graph.graphID, maxRecordsDownloads_, secondsBack_);
            setMaxRecordsDownloadsString(String(maxRecordsDownloads_)) ;
            const secs = convertSecondsToFormattedString(secondsBack_);
            setSecondsBackString(secs) ;
          }
        }

        getInitialMaxRecordsDownloadsAndSecondsBack();
      }, []);

    const handleSetMaxRecordsDownloadsAndSecondsBack = async (sMR: number, sSB: number) => {
      setMaxRecordsDownloadsAndSecondsBack(graph.graphID, sMR, sSB);
    }

    const handleSecondsBackString = async(ss: string) => {
      //console.log('handleSecondsBackString', ss);
      const sec = parseStringToSecond(ss) ;
      const secs = convertSecondsToFormattedString(sec);
      setSecondsBackString(secs) ;
      handleSetMaxRecordsDownloadsAndSecondsBack(parseInt(maxRecordsDownloadsString), sec) ;
    }

    const handleMaxRecordsDownloadsString = async(ss: string) => {
      //console.log('handleMaxRecordsDownloadsString', ss);
      setMaxRecordsDownloadsString(ss) ;
      const sec = parseStringToSecond(secondsBackString) ;
      handleSetMaxRecordsDownloadsAndSecondsBack(parseInt(ss), sec) ;
    }

    /////////////////////////////////////////////////////////////////////////////////////////
    /// Graph Detailed card: Confirm Duplicated Graph
    const ConfirmDuplicatedGraph = () => {
      willRunSetGraphList.current = true;

      const [duplicatedGraphName, setDuplicatedGraphName] = useState("");
      const fetchedRef = useRef(false); // Ref to track if fetch has been done

      useEffect(() => {
        const fetchDuplicateGraphName = async () => {
          if (!fetchedRef.current) {
            fetchedRef.current = true;
            const name = await duplicateGraph(graph.graphID);
            setDuplicatedGraphName(name);
          }
        };

        fetchDuplicateGraphName();
      }, []);

      return (
        <div onClick={() => { setDisplayConfirmDuplicatedGraph(false); }}>
          <div className="log__graphdetail--display-confirmation-duplicated-graph">
            <p className="log__graphdetail--display-confirmation-duplicated-graph--right-icon-container">
              <TfiClose onClick={() => { setDisplayConfirmDuplicatedGraph(false) }} className="log__graphdetail-link" />
            </p>
            <p>Name of duplicated graph: {duplicatedGraphName}</p>
            <CTAbtn className='log__graphdetail--btn'>
              <p>OK</p>
            </CTAbtn>
          </div>
        </div>);
    }

    /////////////////////////////////////////////////////////////////////////////////////////
    /// Graph Detailed card: Default Datasource Settings
    const DefaultDatasourceSettings: React.FC = () => {
      const [selectedDefaultDatasource, setSelectedDefaultDatasource] = useState<string[]>(defaultDataSourceRef.current);

      const handleCheckboxChange = (projectID: string) => {
        setSelectedDefaultDatasource(prevSelected => {
          // console.log('prevSelected', prevSelected);
          let result;
          if (prevSelected.includes(projectID)) {
            result = prevSelected.filter(id => id !== projectID);
          } else {
            result = [...prevSelected, projectID];
          }
          // console.log('Y1', result);
          result = result.filter(id => projectList.some(project => project.projectID === id)); // Filter non-existent projects out.
          // console.log('Y2', result);
          // console.log('Y3', projectList);
          defaultDataSourceRef.current = result;
          publicDataSourceRef.current = projectIDListtoPublic(result, projectList);

          return result;
        });
      };

      useEffect(() => {
        setGraphDefaultSources(graph.graphID, selectedDefaultDatasource);
      }, [selectedDefaultDatasource]);

      return (
        <div>
          <div className="log__graphdetail--set-default-data-source">
            <p className="log__graphdetail--set-default-data-source--right-icon-container">
              <TfiClose onClick={() => { setSetDefaultDataSourceIsVisible(false) }} className="log__graphdetail-link" />
            </p>
            <p>Select default data sources</p>
            <table>
              <thead>
                <tr>
                  <th>Select</th>
                  <th>Name</th>
                  <th>Project Public ID</th>
                </tr>
              </thead>
              <tbody>
                {projectList.map(project => (
                  <tr key={project.projectID}>
                    <td>
                      <input
                        type="checkbox"
                        checked={selectedDefaultDatasource.includes(project.projectID)}
                        onChange={() => handleCheckboxChange(project.projectID)}
                      />
                    </td>
                    <td>{project.name}</td>
                    <td>{project.projectPublic}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      );
    }

    /////////////////////////////////////////////////////////////////////////////////////////
    /// Graph Detailed card: Graph Detail Settings
    const GraphDetailSettings = () => {
      return (
        <div className="log__graphcard--settings">
          <p className="log__graphcard--settings--right-icon-container">
            <TfiClose onClick={() => setSettingsAreVisible(false)} className="log__graphdetail-link" />
          </p>
          <p>
            Rename graph: {" "}
            <input className="log__graphcard--settings--inputfield"
              defaultValue={graphName}
              onBlur={e => handleBlurEditName(e.target.value)}
              onKeyPress={e => {
                if (e.key === 'Enter') {
                  handleBlurEditName((e.target as HTMLInputElement).value);
                }
              }}
            />
          </p>
          <p onClick={() => { setSetDefaultDataSourceIsVisible(true) }} className="log__graphdetail-link">Set default data source</p>
          <p>
            Dataset history (s,m,h,D,M,Y) {" "}
            <input className="log__graphcard--settings--inputfield"
              defaultValue={secondsBackString}
              onBlur={e => handleSecondsBackString(e.target.value)}
            />
            </p>

          <p>
            No. dataset records to handle {" "}
            <input className="log__graphcard--settings--inputfield"
              defaultValue={maxRecordsDownloadsString}
              onBlur={e => handleMaxRecordsDownloadsString(e.target.value)}
            />
            </p>
          <hr/>
          <div className="log__graphdetail-link">
            <CopyString textToCopy={graphPublic} className="log__graphdetail-link_p">
              Public: {graphPublic}{' '}
            </CopyString>
          </div>

          <p className="log__graphdetail-link" onClick={handleResetPublic}>
            Reset Public
          </p>
          <hr/>
          <p className="log__graphdetail-link">
            <a className="log__graphdetail-link"
              href={getImgURL+"?graphPublic="+graphPublic}
            >          
            {getImgURL+"?graphPublic="+graphPublic}
            </a>
          </p>
          <hr/>

          {setDefaultDataSourceIsVisible && (
            <>
              <div
                className="log__datasetcard--set-default-data-source--overlay"
                onClick={() => { setSetDefaultDataSourceIsVisible(false) }}
              />
              <DefaultDatasourceSettings />
            </>
          )}

          <div onClick={() => { setDisplayConfirmDuplicatedGraph(true); }}>
            <p className="log__graphdetail-link">Duplicate graph</p>
          </div>
          {displayConfirmDuplicatedGraph && (
            <>
              <div
                className="log__datasetcard--set-default-data-source--overlay"
                onClick={() => { setDisplayConfirmDuplicatedGraph(false) }}
              />
              <ConfirmDuplicatedGraph />
            </>
          )}

          {/* <p>Start over with AI</p> */}
          <p className="log__graphdetail-link" onClick={() => { setDisplayConfirmationDeleteGraph(true); }}>
            Delete graph
          </p>
          {displayConfirmationDeleteGraph && (
            <div>
              <div className="log__graphdetail--delete_confirmation--overlay" />
              <div className="log__graphdetail--delete_confirmation">
                <div>Delete graph {graphName}?</div>
                <div className="log__graphdetail--delete_confirmation--button-container">
                  <button
                    onClick={() => { yesDeleteData(); setDisplayConfirmationDeleteGraph(false); }}
                    className="log__graphdetail--delete_confirmation--button">Yes</button>
                  <button
                    onClick={() => { setDisplayConfirmationDeleteGraph(false); }}
                    className="log__graphdetail--delete_confirmation--button">No</button>
                </div>
              </div>
            </div>)
          }

        </div>
      );
    }

    /////////////////////////////////////////////////////////////////////////////////////////
    /// Graph Detailed card: Main Card
    if (!detailsAreVisible) return (<></>);

    return (
      <div>
        <div className="log__graphdetail">
          <div className="log__graphdetail-header_box">
            <div className="log__graphdetail-header_box-title">
              {graphName}
            </div>
            <div className="log__graphdetail-header_box-right_icons">
              <TfiSettings onClick={() => setSettingsAreVisible(true)} className="log__graphdetail-link" />
              {" "}
              <TfiClose onClick={setDetailsAreVisibleFalse1} className="log__graphdetail-link" />
            </div>
            {settingsAreVisible && (
              <div
                className="log__graphdetail--settings--overlay"
                onClick={() => setSettingsAreVisible(false)}
              />
            )}
            {settingsAreVisible && (<GraphDetailSettings />)
            }
          </div>
          <p>
            <a href="https://x.com/2minlog" target="_blank">
            <TfiTwitter className="log__graphdetail-link" /> {' '}
            </a>
            <a href="https://www.facebook.com/profile.php?id=61560538064690" target="_blank">
              <TfiFacebook className="log__graphdetail-link" /> {' '}
            </a>

            <a className="log__graphdetail-link"
              href={getImgURL+"?graphPublic="+graphPublic}
            >
              <TfiLink />
            </a>
          </p>
          {/* <p>Graph ID: {graphID}</p> */}
          <GraphElement
            graphPublic={graphPublic}/>
          {/*}
            <div className="log__graphdetail-link">
              <CopyString textToCopy={'Graph URL'} className="log__graphdetail-link_p">
                  Graph URL: Graph URL{' '}
                </CopyString>
            </div>
            */}
          <div className="log__graphdetail--editor-buttons--container">
            <CTAbtn className='log__graphdetail--btn' onClick={handleDeployCode}>
              <p>Deploy</p>
            </CTAbtn>
            <CTAbtn className='log__graphdetail--btn' onClick={handleDownloadCode}>
              <p>Download code</p>
            </CTAbtn>
            <input
              type="file"
              ref={fileInputRef}
              onChange={handleFileChange}
              style={{ display: 'none' }} // Make the file input invisible
            />
            <CTAbtn className='log__graphdetail--btn' onClick={handleUploadFile}>
              <p>Upload file</p>
            </CTAbtn>
          </div>
          <p>Advice: Avoid developing and debugging your code here. It will quickly consume all your CPU and MB limit.</p>
          <Editor
            className="log__graphdetail--editor"
            language="python"
            theme="vs-dark"
            value={code}
            onChange={handleEditorChange}
          />
        </div>

        {displayConfirmationLosingChanges && (
            <div>
              <div className="log__graphdetail--delete_confirmation--overlay" />
              <div className="log__graphdetail--delete_confirmation">
                <div>Lose changes in graph code?</div>
                <div className="log__graphdetail--delete_confirmation--button-container">
                  <button
                    onClick={() => { setDisplayConfirmationLosingChanges(false); }}
                    className="log__graphdetail--delete_confirmation--button">Return to editor</button>
                  <button
                    onClick={() => { setDetailsAreVisibleFalse2(); }}
                    className="log__graphdetail--delete_confirmation--button">Lose changes</button>
                  <button
                    onClick={async () => { await handleDeployCode(); setDetailsAreVisibleFalse2(); }}
                    className="log__graphdetail--delete_confirmation--button">Deploy</button>
                </div>
              </div>
            </div>)
          }


        <div className="log__graphcard--graph-settings__overlay"
          onClick={setDetailsAreVisibleFalse1} />
      </div>
    );
  };




  /////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////
  /// Main graph card

  // const updateInfoBar = useInfoBarUpdater();

  if (!displayThisCard) return (<></>);

  if(freeImgkey=="")
    return (<></>);

  return (
    <>
      <div className="log__graphcard" onClick={setDetailsAreVisibleTrue}>
        <div className="log__graphcard--graph">
          <ImageLoader
            className="log__graphcard--graph__img"
            src={getImgURL+"?graphPublic="+graphPublic+"&freeImgKey="+freeImgkey}
            alt="Graph"
            key={keyMainCard}
          />
        </div>
        <div>
          <p>{graphName}</p>
          <p>
            { /*
            <TfiTwitter className="log__graphdetail-link" /> {' '}
            <TfiFacebook className="log__graphdetail-link" /> {' '}
            <TfiLinkedin className="log__graphdetail-link" /> {' '}
            */ }
            <a className="log__graphdetail-link"
              href={getImgURL+"?graphPublic="+graphPublic}
                >
              <TfiLink />
            </a>
          </p>
        </div>
      </div>
      {(detailsAreVisible &&
        <div>
          <div className="log__graphcard--graph-settings">
            <GraphDetail />
          </div>
        </div>
      )}
    </>
  );
}

export default GraphCard;

export const NewGraphCard = () => {
  const handleCreateNewGraph = async () => {
    const graph = await createNewGraph();
    // console.log(xx) ;

    const initial_code = await getFileContent(defaultCode);
    // console.log(initial_code);
    await setGraphCode(graph.graphID, initial_code) ;
    rerenderHack(new Date().getTime());

    // console.log('New graph created.')
    // await runSetDatasetList();
    // console.log('Updated list.')
  }


  return (
    <>
      <div className="log__graphcard log__graphcard--plus--container log__graphdetail-link" onClick={handleCreateNewGraph}>
        <div className="log__graphcard log__graphcard--plus--container-circle">
          <TfiPlus />
        </div>
      </div>
    </>
  );
}