import { gql } from '@apollo/client';
// import { useQuery } from '@apollo/client';
// import { Auth } from 'aws-amplify';

import { createHttpLink } from '@apollo/client/link/http';
import { setContext } from '@apollo/client/link/context';
import { GRAPHQL_API_URI, getImgURL } from '../config';
import { ApolloClient, InMemoryCache } from '@apollo/client';
import { Auth } from 'aws-amplify';

import { updateInfoBarError, updateInfoBarInfo } from '../pages'


/*
Write:
https://api.2minlog.com/log?project_secret=SEC-384d8de2-23af-4e58-b3a8-052e1ae5631e&def=qqq&T=97.2&time=2023-08-18T13:09:05.664Z
while true; do wait; for I in `seq 20`; do echo $I ; wget -O- --timeout=10 'https://api.2minlog.com/log?project_secret=SEC-384d8de2-23af-4e58-b3a8-052e1ae5631e&def=qqq&T=97.2' & done; done
curl -XPOST --user "2minlog:SEC-51eaeb7a-d56b-4902-a270-eed27e6a8baa" https://api1.2minlog.com/post -d '{"payload":"hello world!"}'

Read:
https://api.2minlog.com/img?filename_extension=.csv&projectID=ID-ddffbc09-8dac-40eb-a1b7-e8ff2ab548a9
https://api.2minlog.com/img/img?filename_extension=.jpg&projectID=ID-4f588341-b7db-4c6d-925b-bf89e11fdc0a
https://api.2minlog.com/img?projectID=ID-4f588341-b7db-4c6d-925b-bf89e11fdc0a,ID-295df3ee-ece0-429c-80f3-56c07c9c848e
https://api.2minlog.com/img?filename_extension=jpg&projectPublic=PUB-5a25e4d8-b474-46e5-b7a5-06c4e420eb23,PUB-64ae8d34-b910-4c47-a03a-bac6c79c091d

type Graph {
	graphID: String!
	time: String!
	email: String!
	graphPublic: String!
	graphName: String!
	payLoad: String!
}

type HttpResponse {
	statusCode: Int!
	body: String!
}

type Project {
	projectID: String!
	email: String!
	time: String!
	projectSecret: String!
	projectPublic: String!
	name: String!
	payLoad: String
}

type Mutation {
	createProject(name: String!): Project!
	deleteProjectContent(projectID: String!): HttpResponse!
	deleteProjectEnvelopeAndContent(projectID: String!): Boolean!
	renameProject(projectID: String!, name: String!): Project!
	resetSecret(projectID: String!): Project!
	resetPublic(projectID: String!): Project!
	setMaxRecords(projectID: String!, maxRecords: Int!): HttpResponse!
	createGraph(graphName: String!): Graph!
	renameGraph(graphID: String!, graphName: String!): Graph!
	deleteGraph(graphID: String!): Boolean!
	setGraphCode(graphID: String!, code: String!): HttpResponse!
	setGraphDefaultSources(graphID: String!, defaultSources: [String]!): HttpResponse!
	setMaxRecordsDownloadsAndSecondsBack(graphID: String!, maxRecordsDownloads: Int!, secondsBack: Int!): HttpResponse!
	resetGraphPublic(graphID: String!): Graph!
	cancelSubscription: HttpResponse!
  getFreeImageKey: HttpResponse!
}

type Query {
	listProjects: [Project]
	getProjectEnvelope(projectID: String!): Project!
	listGraphs: [Graph]
	getGraph(graphID: String!): Graph
	getNumberOfRecords(projectID: String!): HttpResponse!
	getStripeIDandProduct: HttpResponse!
	getCustomerSessionClientSecret: HttpResponse!
	getCustomerMeter(daysAgo: Int!): HttpResponse!
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
PK                    | SK              | GSI1PK          | GSI1SK          | GSI2PK               | GSI3PK               | name      | payLoad                                    | payLoad1
PROJECTID#ProjectID   | null            | EMAIL#email     | TIME#time       | SECRET#projectSecret | PUBLIC#projectPublic | name      | {maxRecords}                               |
GRAPHID#GraphID       | null            | EMAIL_G#email   | TIME#time       |                      | PUBLIC_G#graphPublic | graphName | {code: string, default_source: [string], } |
MONITOR_SECONDS#email | TIME#yyyy-mm-dd | MONITOR_SECONDS | TIME#yyyy-mm-dd |                      |                      |           | seconds                                    | records
MONITOR_BYTES#email   | TIME#yyyy-mm-dd | MONITOR_BYTES   | TIME#yyyy-mm-dd |                      |                      |           | bytes                                      | records
IMGKEY#email          | ISO_VALIDITY    |                 |                 | SECRET_BYTES         |                      |           |                                            | 
*/


interface Project {
  projectID: string;
  email: string;
  time: string;
  projectSecret: string;
  projectPublic: string;
  name: string;
  payLoad?: string;
}

interface Graph {
  graphID: string;
  email: string;
  time: string;
  graphPublic: string;
  graphName: string;
  payLoad: string;
}

interface HttpResponse {
  statusCode: number;
  body: string;
}


const httpLink = createHttpLink({
    uri: GRAPHQL_API_URI,
  });

  const authLink = setContext(async (_, { headers }) => {
    // Retrieve the Cognito token
    const session = await Auth.currentSession();
    const token = session.getIdToken().getJwtToken();

    // Return the headers with the authorization token
    return {
      headers: {
        ...headers,
        Authorization: token ? `Bearer ${token}` : '',
      },
    };
  });

const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'network-only',
      },
      query: {
        fetchPolicy: 'network-only',
      },
    },
});

//////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////
/// Datasets part

export const getDatasets =  async () => { 
    const x = await client.query({
      query: gql`
        query ListProjects {
            listProjects {
                projectID
                email
                time
                projectSecret
                projectPublic
                name
                payLoad
            }
        }`,
      fetchPolicy: "no-cache"
    })
  
    // console.log('QQQQQQQQQQQQQQQQQQ', x?.data?.listProjects) ;

    const listProjects = x?.data?.listProjects || [] ;
    //return listProjects ;

    const reverseListProjects = [...listProjects].reverse()

    return reverseListProjects ;
}


export const getProjectEnvelope = async (projectID: string): Promise<Project | null> => {
  let projectEnvelope: Project | null = null;

  try {
    const result = await client.query({
      query: gql`
        query GetProjectEnvelope($projectID: String!) {
          getProjectEnvelope(projectID: $projectID) {
            projectID
            email
            time
            projectSecret
            projectPublic
            name
            payLoad
          }
        }
      `,
      fetchPolicy: "network-only",
      variables: {
        projectID: projectID
      }
    });

    projectEnvelope = result?.data?.getProjectEnvelope;
    // console.log("OK13", projectEnvelope);
  } catch (err) {
    console.error("ERR13", err);
    updateInfoBarError('getProjectEnvelope ' + err ) ;
  }

  return projectEnvelope;
}

export const getNumberOfRecords = async (projectID: string) => {
  console.log('getNumberOfRecords', projectID);
  try {
    const result = await client
      .query({
        query: gql`
          query GetNumberOfRecords($projectID: String!) {
            getNumberOfRecords(projectID: $projectID) {
              statusCode
              body
            }
          }
        `,
      fetchPolicy: "network-only",
      variables: {
        projectID: projectID
      }
    })

    if( result.data.getNumberOfRecords.statusCode !== 200 ) {
      console.log("ERR getNumberOfRecords - code 5wje78", result.data.getNumberOfRecords) ;
      updateInfoBarError('getNumberOfRecords ' + result.data.getNumberOfRecords ) ;
      return -1 ;
    }

    // console.log('getNumberOfRecords', result.data.getNumberOfRecords.body);

    const body = result.data.getNumberOfRecords.body ;
    const js = JSON.parse(body) ;
    const noOfRecords = js.noOfRecords ;
    return noOfRecords ;

  } catch(err) {
    console.log("ERR getNumberOfRecords - code 382g7", err) ;
    updateInfoBarError('getNumberOfRecords ' + err ) ;
    return -2 ;
  }
}

export const createNewDataset = async () => {
  updateInfoBarInfo('Creating a new dataset. Wait a few seconds...')

  const currentDate = new Date();
  const formattedDate = currentDate.toISOString()
  .replace(/[^T^\d]/g, '-') // Replace non-numeric characters with hyphens
  .slice(0, 19) // Format: YYYY-MM-DDTHH-MM-SS
  .replace('T', '--'); // Replace 'T' with double hyphens

  const projectName = `DatasetX--${formattedDate}`;

  const result = client
    .mutate({
      mutation: gql`
    mutation {
      createProject(name: "${projectName}") {
        projectID
        email
        time
        projectSecret
        projectPublic
        name
        payLoad
      }
    }
    `,
    })
    .then(async (result) => {
      updateInfoBarInfo('New Dataset created.')
      // console.log("Created new project", result);
    })
    .catch(err => {
      console.log("ERR2", err);
      updateInfoBarError('createNewDataset ' + err );
    });
    return result;
}

export const deleteProjectContent = async (projectID: string) => {

  try {
    const result = await client.mutate({
      mutation: gql`
        mutation DeleteProjectContent($projectID: String!) {
          deleteProjectContent(projectID: $projectID) {
            statusCode
            body
          }
        }
      `,
      variables: {
        projectID: projectID
      }
    });

    // console.log("deleteProjectContent", JSON.stringify(result));
  } catch (err) {
    console.log("ERR - deleteProjectContent", err);
    updateInfoBarError('deleteProjectContent ' + err );
  };
}


export const deleteProjectEnvelopeAndContent = async (projectID: string) => {
  const mutation = gql`
    mutation DeleteProjectEnvelopeAndContent($projectID: String!) {
      deleteProjectEnvelopeAndContent(projectID: $projectID)
    }
  `;

  client.mutate({
    mutation: mutation,
    variables: {
      projectID: projectID
    }
  })
    .then(async (result) => {
      // console.log("OK5", projectID, result);
      // await runSetListProject();
      // setTimeout(runSetListProject, 10000);
    }
    )
    .catch(err => {
      console.log("ERR - deleteProjectEnvelopeAndContent", err);
      updateInfoBarError('deleteProjectEnvelopeAndContent ' + err );
    });
}


export const renameProject = async (projectID: string, name: string) => {
  try {
    await client.mutate({
      mutation: gql`
        mutation RenameProject($projectID: String!, $name: String!) {
          renameProject(projectID: $projectID, name: $name) {
            projectID
            email
            time
            projectSecret
            projectPublic
            name
            payLoad
          }
        }
      `,
      variables: { projectID, name }
    });

    // Assuming runSetListProject is an async function you want to call after renaming the project
    // await runSetListProject();
    // If you need a delay before calling runSetListProject, consider using a utility function for delay instead of setTimeout.
    // Utility function for delay (if needed)
    // const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

    // console.log("Project renamed successfully:", data);
  } catch (error) {
    console.error("ERR - renameProject", error);
  
    // Check if error is an instance of Error and thus has a message property
    if (error instanceof Error) {
      updateInfoBarError(`renameProject ${error.message}`);
    } else {
      // If error is not an Error object, log it as a string
      updateInfoBarError(`renameProject ${String(error)}`);
    }
  }
};


export const setMaxRecords = async (projectID: string, maxRecords: number) => {
  // console.log("setMaxRecords", projectID, maxRecords);

  client
    .mutate({
      mutation: gql`
        mutation SetMaxRecords($projectID: String!, $maxRecords: Int!) {
          setMaxRecords(projectID: $projectID, maxRecords: $maxRecords) {
            statusCode
            body
          }
        }
      `,
      variables: {
        projectID: projectID,
        maxRecords: maxRecords
      }
    })
    .then(async (result) => {
      // console.log("setMaxRecords", result);
    }
    )
    .catch(err => {
      console.log("ERR - setMaxRecords", err);
      updateInfoBarError('setMaxRecords ' + err );
    });
}

export const resetSecret = async (projectID: string): Promise<Project | null> => {
  const mutation = gql`
    mutation ResetSecret($projectID: String!) {
      resetSecret(projectID: $projectID){
        projectID
        email
        time
        projectSecret
        projectPublic
        name
        payLoad
      }
    }
  `;

  try {
    const result = await client.mutate({
      mutation: mutation,
      variables: {
        projectID: projectID
      }
    });

    // console.log('resetSecret1', result);
    return result?.data?.resetSecret ;

  } catch( err ){
    console.log("ERR - resetSecret", err);
    updateInfoBarError('resetSecret ' + err );
    return null ;
  }
}

export const resetPublic = async (projectID: string): Promise<Project | null> => {
  // console.log('About to reset public', projectID) ;
  const mutation = gql`
    mutation ResetPublic($projectID: String!) {
      resetPublic(projectID: $projectID){
        projectID
        email
        time
        projectSecret
        projectPublic
        name
        payLoad
      }
    }
  `;
try {
      const result = await client.mutate({
        mutation: mutation,
        variables: {
          projectID: projectID
        }
      });

      return result?.data?.resetPublic ;

    } catch( err ){
      console.log("ERR - resetPublic", err);
      updateInfoBarError('resetPublic ' + err );
      return null ;
    }
}

export const getProjectDataAsCSV = async (publicID: string, maxRecordsDownloads?: number): Promise<string> => {
  // console.log("Fetching project data:", publicID, maxRecords);

  try {
    let url: string;
    const freeImgKey = await getFreeImageKey() ;

    if (maxRecordsDownloads !== undefined) {
      url = `${getImgURL}?filenameExtension=csv&maxRecordsDownloads=${maxRecordsDownloads}`+
      `&datasetPublic=${publicID}`+
      `&freeImgKey=${freeImgKey}` ;
    } else {
      // Replace 'localDataset?.datasetPublic' with a valid string or define 'localDataset'
      url = `${getImgURL}?filenameExtension=csv&datasetPublic=${publicID}&freeImgKey=${freeImgKey}`;
    }

    // console.log('URL1', url);

    const response = await fetch(url);

    // console.log('response', response) ;

    if(response?.status === 403) {
      updateInfoBarError('Invalid subscription');
      // console.log(response);
      // console.log(await response.text());
      return "";
    }

    if (!response.ok) {
      updateInfoBarError('Error - getProjectDataAsCSV');
      throw new Error('Network response was not ok');
    }

    const data = await response.text();
    // Process or return 'data' as needed
    return data;
  } catch( err ){
    console.log("ERR - getProjectDataAsCSV", err);
    updateInfoBarError('getProjectDataAsCSV - code 8735f ' + err );
    return "" ;
  }

}

//////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////
/// Graph part
export const createNewGraph = async () => {
  const currentDate = new Date();
  const formattedDate = currentDate.toISOString()
    .replace('T', '--');

  const graphName = `GraphX--${formattedDate}`;

  try {
    const result = await client.mutate({
      mutation: gql`
        mutation createGraph($graphName: String!) {
          createGraph(graphName: $graphName) {
            graphID
            email
            time
            graphPublic
            graphName
            payLoad
          }
        }
      `,
      variables: {
        graphName,
      },
    });

    // console.log("Created new graph", result);
    return result.data.createGraph;
  } catch (err) {
    console.log("Error creating new graph", err);
    updateInfoBarError('createNewDataset ' + err);
    throw err;
  }
}

export const duplicateGraph = async (oldGraphID: string) => {
  const oldGraphPromise = getGraph(oldGraphID);
  const newGraphPromise = createNewGraph();

  const oldGraph = await oldGraphPromise;
  const newGraph = await newGraphPromise;

  // console.log("oldGraph1", oldGraph);
  // console.log("newGraph2", newGraph);

  if (!oldGraph) {
    console.log("Error: oldGraph not found");
    updateInfoBarError('duplicateGraph');
    throw new Error('oldGraph not found');
  }

  try {
    const newGraphName = oldGraph.graphName + '-(copy)'
    await renameGraph(newGraph.graphID, newGraphName);
    const payLoad = JSON.parse(oldGraph.payLoad);
    // console.log('payLoad.default_source', payLoad.default_source);
    await setGraphDefaultSources(newGraph.graphID, payLoad.default_source);
    await setGraphCode(newGraph.graphID, payLoad.code);

    let mr = null ;
    if( payLoad.maxRecordsDownloads && payLoad.secondsBack ) {
      await setMaxRecordsDownloadsAndSecondsBack(newGraph.graphID, payLoad.maxRecordsDownloads, payLoad.secondsBack);
    }

    return newGraphName;
  } catch (err) {
    console.log("Error duplicateGraph", err);
    updateInfoBarError('duplicateGraph ' + err);
    throw err;
  }

  return "Error";
}

export const listGraphs =  async () => { 
  try {
    const x = await client.query({
      query: gql`
        query listGraphs {
          listGraphs {
              graphID
              time
              email
              graphPublic
              graphName
              payLoad
            }
        }`,
      fetchPolicy: "no-cache"
    })

    // console.log("listGraphs", x);
    const listGraphs = x?.data?.listGraphs || [] ;
    const reverseListGraphs = [...listGraphs].reverse()
    return reverseListGraphs ;
  } catch(err) {
    console.log("ERR3", err);
    updateInfoBarError('listGraphs ' + err );
  }
  return [] ;
}

export const getGraph = async (graphID: string): Promise<Graph | null> => {
  let graph: Graph | null = null;

  // console.log('getGraph', graphID);

  try {
    const result = await client.query({
      query: gql`
        query GetGraph($graphID: String!) {
          getGraph(graphID: $graphID) {
            graphID
            email
            time
            graphPublic
            graphName
            payLoad
          }
        }
      `,
      fetchPolicy: "network-only",
      variables: {
        graphID: graphID
      }
    });

    graph = result?.data?.getGraph;
    // console.log("OK13", projectEnvelope);
  } catch (err) {
    console.error("Error - code u298tsl", err);
    updateInfoBarError('getGraph ' + err ) ;
  }

  return graph;
}

export const deleteGraph = async (graphID: string) => {

  try {
    await client.mutate({
      mutation: gql`
        mutation DeleteGraph($graphID: String!) {
          deleteGraph(graphID: $graphID) 
        }
      `,
      variables: {
        graphID: graphID
      }
    });

    // console.log("deleteGraph", JSON.stringify(result));
  } catch (err) {
    console.log("ERR - deleteGraph", err);
    updateInfoBarError('deleteGraph ' + err );
  };
}

export const renameGraph = async (graphID: string, graphName: string) => {
  try {
    await client.mutate({
      mutation: gql`
        mutation RenameGraph($graphID: String!, $graphName: String!) {
          renameGraph(graphID: $graphID, graphName: $graphName) {
            graphID
            email
            time
            graphPublic
            graphName
            payLoad
          }
        }
      `,
      variables: { graphID, graphName }
    });

  } catch (error) {
    console.error("ERR - renameGraph", error);
  
    // Check if error is an instance of Error and thus has a message property
    if (error instanceof Error) {
      updateInfoBarError(`renameGraph ${error.message}`);
    } else {
      // If error is not an Error object, log it as a string
      updateInfoBarError(`renameGraph ${String(error)}`);
    }
  }
};

export const resetGraphPublic = async (graphID: string): Promise<Graph | null> => {
  const mutation = gql`
    mutation ResetGraphPublic($graphID: String!) {
      resetGraphPublic(graphID: $graphID){
        graphID
        email
        time
        graphPublic
        graphName
        payLoad
  }
    }
  `;
try {
      const result = await client.mutate({
        mutation: mutation,
        variables: {
          graphID: graphID
        }
      });

      return result?.data?.resetGraphPublic ;

    } catch( err ){
      console.log("ERR - resetGraphPublic", err);
      updateInfoBarError('resetGraphPublic ' + err );
      return null ;
    }
}

export const setGraphDefaultSources = async (graphID: string, defaultSources: string[]): Promise<null> => {
  // console.log('About to set default sources for graph', graphID, {defaultSources}) ;
  const mutation = gql`
    mutation SetGraphDefaultSources($graphID: String!, $defaultSources: [String!]!) {
      setGraphDefaultSources(graphID: $graphID, defaultSources: $defaultSources){
        statusCode
        body
      }
    }
  `;
  try {
    const result = await client.mutate({
      mutation: mutation,
      variables: {
        graphID: graphID,
        defaultSources: defaultSources
      }
    });
    // console.log('setGraphDefaultSources', result);
    return null;
    // return result.data.setGraphDefaultSources; /* Promise<HttpResponse | null> */

  } catch (err) {
    console.log("ERR - setGraphDefaultSources", err);
    updateInfoBarError('setGraphDefaultSources ' + err);
    return null;
  }
};

export const setGraphCode = async (graphID: string, code: string): Promise<null> => {
  // console.log('About to set code for graph', graphID, code) ;
  const mutation = gql`
    mutation SetGraphCode($graphID: String!, $code: String!) {
      setGraphCode(graphID: $graphID, code: $code){
        statusCode
        body
      }
    }
  `;
  try {
    const result = await client.mutate({
      mutation: mutation,
      variables: {
        graphID: graphID,
        code: code
      }
    });

    // console.log('setGraphCode', result);
    updateInfoBarInfo('New version deployed.' );
    return null;

  } catch (err) {
    console.log("ERRx273 - setGraphCode", err);
    updateInfoBarError('setGraphCode ' + err);
    return null;
  }
};

////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////// Stripe

export const getStripeIDandProduct = async () => { 
  try {
    // console.log('About to run getStripeIDandProduct');
    const x = await client.query({
      query: gql`
        query getStripeIDandProduct {
          getStripeIDandProduct {
            statusCode
            body
          }
        }`,
      fetchPolicy: "no-cache"
    });

    // console.log("getStripeIDandProduct1", x);
    if( x.data.getStripeIDandProduct.statusCode === 200 ) {
      const result = JSON.parse(x.data.getStripeIDandProduct.body);
      // console.log('getStripeIDandProduct2', result);
      return result ;
    }

    // updateInfoBarError('getStripeIDandProduct ' + x.data.getStripeIDandProduct.body );    

  } catch(err) {
    console.log("ERR7x getStripeIDandProduct", err);
    // updateInfoBarError('getStripeIDandProduct ' + err );
  }

  return { customer_id: "None", subscription: "None" } ;
}

export const getCustomerSessionClientSecret = async () => { 
  try {
    // console.log('About to run getCustomerSessionClientSecret');
    const x = await client.query({
      query: gql`
        query getCustomerSessionClientSecret {
          getCustomerSessionClientSecret {
            statusCode
            body
          }
        }`,
      fetchPolicy: "no-cache"
    });

    // console.log("getCustomerSessionClientSecret", x);
    if( x.data.getCustomerSessionClientSecret.statusCode === 200 ) {
      const result = JSON.parse(x.data.getCustomerSessionClientSecret.body);
      // console.log(result);
      return result ;
    }

    // updateInfoBarError('getCustomerSessionClientSecret ' + x.data.getCustomerSessionClientSecret.body );    

  } catch(err) {
    console.log("ERR86x getCustomerSessionClientSecret", err);
    // updateInfoBarError('getCustomerSessionClientSecret ' + err );
  }

  return { customer_id: "None", subscription: "None" } ;
}

export const getCustomerMeter = async () => { 
  try {
    // console.log('About to run getCustomerMeter');
    const query = gql`
    query getCustomerMeter($daysAgo: Int!) {
      getCustomerMeter(daysAgo: $daysAgo) {
        statusCode
        body
      }
    }` ;

    const x = await client.query({
      query: query,
      variables: {
        daysAgo: 5
      },
      fetchPolicy: "no-cache"
    });

    // console.log("getCustomerMeter1", x);
    if( x.data.getCustomerMeter.statusCode === 200 ) {
      const result = JSON.parse(x.data.getCustomerMeter.body);
      // console.log("getCustomerMeter2", result);
      return result ;
    }
    // updateInfoBarError('getCustomerSessionClientSecret ' + x.data.getCustomerSessionClientSecret.body );    

  } catch(err) {
    console.log("ERR837 getCustomerMeter", err);
    // updateInfoBarError('getCustomerSessionClientSecret ' + err );
  }

  return {} ;
}

export const cancelSubscription = async () => { 
  try {
    console.log('About to run cancelSubscription');
    const x = await client.query({
      query: gql`
        mutation cancelSubscription {
          cancelSubscription {
            statusCode
            body
          }
        }`,
      fetchPolicy: "no-cache"
    });
    console.log('Ran cancelSubscription');

    console.log("cancelSubscription", x);
    if( x.data.cancelSubscription.statusCode === 200 ) {
      const result = JSON.parse(x.data.cancelSubscription.body);
      console.log('cancelSubscription2', result);
      return result ;
    }

  } catch(err) {
    console.log("ERR48x cancelSubscription", err);
    // updateInfoBarError('cancelSubscription ' + err );
  }
}

export const setMaxRecordsDownloadsAndSecondsBack = async (graphID: string, maxRecordsDownloads: number, secondsBack: number) => {
  // console.log('setMaxRecordsDownloadsAndSecondsBack', graphID, maxRecordsDownloads, secondsBack);

  client
    .mutate({
      mutation: gql`
        mutation setMaxRecordsDownloadsAndSecondsBack($graphID: String!, $maxRecordsDownloads: Int!, $secondsBack: Int!) {
          setMaxRecordsDownloadsAndSecondsBack(graphID: $graphID, maxRecordsDownloads: $maxRecordsDownloads, secondsBack: $secondsBack) {
            statusCode
            body
          }
        }
      `,
      variables: {
        graphID: graphID,
        maxRecordsDownloads: maxRecordsDownloads,
        secondsBack: secondsBack
      }
    })
    .then(async (result) => {
      //console.log("setMaxRecordsDownloadsAndSecondsBack", result);
    }
    )
    .catch(err => {
      console.log("ERR - setMaxRecordsDownloadsAndSecondsBack", err);
      updateInfoBarError('setMaxRecordsDownloadsAndSecondsBack ' + err );
    });
}

const outdated = "2000-01-01T00:00:00.000Z";
let isoValidity = outdated;
let key = "";
let fetchPromise: Promise<{ key: string, isoValidity: string }> | null = null;  // Explicit typing

export const getFreeImageKey = async () => {
  console.log('getFreeImageKey - start', ) ;


  const isoStringMinutesFromNow = (m: number) => {
      const now = new Date();
      now.setMinutes(now.getMinutes() + m);
      return now.toISOString();
  };

  const doGetFreeImageKey = async () => {
    console.log('getFreeImageKey - fetch new') ;
    try {
          const result = await client.mutate({
              mutation: gql`
                  mutation getFreeImageKey {
                      getFreeImageKey {
                          statusCode
                          body
                      }
                  }
              `
          });

          console.log("getFreeImageKey", result);
          if (result.data.getFreeImageKey.statusCode === 200) {
              return JSON.parse(result.data.getFreeImageKey.body);
          } else {
              console.log("ERR - getFreeImageKey2", result);
              updateInfoBarError('getFreeImageKey2 ' + result);
              return { key: '', isoValidity: outdated };
          }
      } catch (err) {
          console.log("ERR - getFreeImageKey", err);
          updateInfoBarError('getFreeImageKey ' + err);
          return { key: '', isoValidity: outdated };
      }
  };

  const in2min = isoStringMinutesFromNow(2);

  if (new Date(isoValidity) < new Date(in2min)) {
    if (!fetchPromise) {
        fetchPromise = doGetFreeImageKey();
    }
    const result = await fetchPromise;
    isoValidity = result.isoValidity;
    key = result.key;
    fetchPromise = null;
  }

  console.log('getFreeImageKey - end') ;
  return key;
};
