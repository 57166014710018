import './projects.css';
import { useState, useEffect} from 'react';
import {IntroHeader, IntroSubHeader} from './../../containers';

import { getDatasets, createNewDataset, 
  listGraphs } from '../../middleware/apollo';

// import { redirect } from "react-router-dom";
// import { Navigate } from 'react-router-dom';

import { Navbar, Intro, DatasetCard, GraphCard } from './../../containers';
import { CTAbtn } from '../../components';

import { NewGraphCard } from './../../containers/graphcard/GraphCard';

import { updateInfoBarInfo } from '../../pages/infobar/InfoBar'

interface ProjectInterface {
  projectID: string;
  email: string;
  time: string;
  projectSecret: string;
  projectPublic: string;
  name: string;
  payLoad: string | null;
}

interface GraphInterface {
  graphID: string;
  time: string;
  email: string;
  graphPublic: string;
  graphName: string;
  payLoad: string;
}

const Project = () => {
  const [selectedDataset, setSelectedDataset] = useState(true);

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //// DatasetContent
  const DatasetContent = () => {
    const [datasetList, setDatasetList] = useState<ProjectInterface[]>([]);

    const runSetDatasetList = async () => {
      const datasets = await getDatasets() ;
      // console.log(JSON.stringify(datasets));
      setDatasetList(datasets) ;
    }

    useEffect(() => {
      runSetDatasetList();
    }, []);
  
    const handleCreateNewDataset = async () => {
      await createNewDataset();
      // console.log('New dataset created.')
      await runSetDatasetList();
      // console.log('Updated list.')
    }

    return(
      <>
      <div>
        <CTAbtn className="log__projects--create_dataset-text log__projects--create_dataset" onClick = {handleCreateNewDataset}>
          <p className="log__projects--create_dataset-text">Create a new Dataset</p>
        </CTAbtn>
      </div>
      {datasetList.map((data, index) => (
        <DatasetCard key={data.projectID} dataset={data} />
      ))}
      </>
    );

  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //// GraphContent
  const GraphContent = () => {
    const [graphList, setGraphList] = useState<GraphInterface[]>([]);
    const [projectList, setProjectList] = useState<ProjectInterface[]>([]);

    const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

    const runSetGraphList = async () => {
      let graphs = await listGraphs() ;
      // console.log('List of graphs:', JSON.stringify(graphs));
      setGraphList(graphs) ;
      delay(200);
      graphs = await listGraphs() ;
      // console.log('List of graphs:', JSON.stringify(graphs));
      setGraphList(graphs) ;
      delay(500);
      graphs = await listGraphs() ;
      // console.log('List of graphs:', JSON.stringify(graphs));
      setGraphList(graphs) ;
    }

    useEffect(() => {
      runSetGraphList();
    }, []);


    useEffect(() => {
      const runSetProjectList = async () => {
        const projects = await getDatasets() ;
        // console.log('OP1', projects);
        setProjectList(projects);
      }

      runSetProjectList();
    }, []);

    

    return(
      <div className="log_projects--datasets_graphs__container">
        {graphList.map((graph, index) => (
          <GraphCard key={graph.graphID} graph={graph} projectList={projectList} runSetGraphList={runSetGraphList}/>
        ))}
        <div onClick={runSetGraphList}> {/* This is the new graph card */}
          <NewGraphCard/> 
        </div>
      </div>
    );
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //// main Project element
  return (
    <>
      <Navbar />
      <Intro>
        <IntroHeader>
          2minlog
        </IntroHeader>
      </Intro>

      <div className="log_projects--datasets_graphs__container">
        <div 
          className={"log_projects--datasets_graphs__items log_projects--datasets_graphs__items_left" + (selectedDataset ? " log_projects--datasets_graphs__items_selected" : "")}
          onClick={() => setSelectedDataset(true)}
        >
          <p>Datasets</p>
          {selectedDataset ? <div className="log_projects--datasets_graphs__items-hbar" /> : null}
        </div>
        <div 
          className={"log_projects--datasets_graphs__items log_projects--datasets_graphs__items_right" + (!selectedDataset ? " log_projects--datasets_graphs__items_selected" : "")}
          onClick={() => setSelectedDataset(false)}
          >
          <p>Graphs</p>
          {!selectedDataset ? <div className="log_projects--datasets_graphs__items-hbar" /> : null}
        </div>
      </div>

      {selectedDataset ?
        <DatasetContent/>
        :
        <GraphContent/>}
    </>
  );
}

export default Project;