import './user.css';

import { updateInfoBarError } from '../../pages'

import { useState, useEffect} from 'react';
import { Auth } from 'aws-amplify';
import { Authenticator } from '@aws-amplify/ui-react';
import awsConfig from '../../aws-exports';
import { Amplify } from 'aws-amplify';
import { Link } from "react-router-dom";
import { RiCloseLine } from 'react-icons/ri';
import React from "react" ;
import {pricingTableId, publishableKey, customerPortalURL, oauthDomain} from '../../config'
import { getStripeIDandProduct, getCustomerSessionClientSecret, getCustomerMeter,
  getDatasets, deleteProjectEnvelopeAndContent, deleteGraph, listGraphs,
  cancelSubscription } from '../../middleware/apollo';


/*
https://docs.stripe.com/billing/subscriptions/usage-based/pricing-models#fixed-fee-and-overage-model
*/

// https://docs.amplify.aws/lib/auth/social/q/platform/js/#configure-auth-category
const isLocalhost = Boolean(
  window.location.hostname === "localhost" ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === "[::1]" ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

const [
  localRedirectSignIn,
  productionRedirectSignIn,
] = awsConfig.oauth.redirectSignIn.split(",");

const [
  localRedirectSignOut,
  productionRedirectSignOut,
] = awsConfig.oauth.redirectSignOut.split(",");

const updatedAwsConfig = {
  ...awsConfig,
  oauth: {
    ...awsConfig.oauth,
    redirectSignIn: isLocalhost ? localRedirectSignIn : productionRedirectSignIn,
    redirectSignOut: isLocalhost ? localRedirectSignOut : productionRedirectSignOut,
  }
}

// https://watilde.medium.com/how-to-use-a-custom-domain-of-cognito-hosted-ui-with-amplify-de6b772281ba
// Refs: https://github.com/aws-amplify/amplify-cli/issues/1880
updatedAwsConfig.oauth.domain = oauthDomain;

try {
  Amplify.configure(updatedAwsConfig);
} catch(err) {
  console.log("ERR - 9823h87g435 Amplify.configure(updatedAwsConfig)", err);
}


const loadScript = (src: string, id: string): Promise<void> => {
  return new Promise((resolve, reject) => {
    // Check if the script is already loaded
    if (document.getElementById(id)) {
      resolve();
      return;
    }

    const script = document.createElement('script');
    script.id = id;
    script.src = src;
    script.async = true;
    script.onload = () => resolve();
    script.onerror = () => reject(new Error(`Failed to load the script: ${src}`));

    document.head.appendChild(script);
  });
};

function delay(time: number): Promise<void> {
  return new Promise(resolve => setTimeout(resolve, time));
}

const User = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [pressedDelete, setPressedDelete] = useState(false);
  const [customerEmail, setCustomerEmail] = useState("");
  const [customerSessionClientSecret, setCustomerSessionClientSecret] = useState("");
  const [clientReferenceId, setClientReferenceId] = useState("");
  const [textInput, setTextInput] = useState('');
  const [customerProduct, setCustomerProduct] = useState("");
  const [customerProductStart, setCustomerProductStart] = useState("");
  const [customerProductEnd, setCustomerProductEnd] = useState("");
  const [customerProductActive, setCustomerProductActive] = useState("");
  const [customerConsumption, setCustomerConsumption] = useState("");

  useEffect(() => { // setCustomerConsumption(tableString);
    if(!isAuthenticated) { 
        return ; 
    }

    async function runFunction() {
      interface MeterData {
        seconds: { [date: string]: number };
        bytes: { [date: string]: number };
        bytes_n: { [date: string]: number };
        seconds_n: { [date: string]: number };
        dates: string[];
      }
      
      // const data0 = await getCustomerMeter();
      // setCustomerConsumption(JSON.stringify(data0));
    
      // Assuming getCustomerMeter() is an async function returning data of type MeterData
      const data: MeterData = await getCustomerMeter() as MeterData;
    
      // Create table headers
      let tableString = "| Date      Hour | Seconds    | Bytes           | MB         | # Events   |\n";
      tableString    += "|----------------|------------|-----------------|------------|------------|\n";
    
      data.dates.sort().reverse();


      // Fill table with data
      for (let date of data.dates) {
        const formattedSeconds = data.seconds[date]?.toLocaleString('en-US', { maximumFractionDigits: 1 }).padStart(10, " ") || 'N/A';
        const formattedBytes = data.bytes[date]?.toLocaleString('en-US', { maximumFractionDigits: 0 }).padStart(15, " ") || 'N/A';
        const formattedMB = (data.bytes[date]/1.e6)?.toLocaleString('en-US', { maximumFractionDigits: 2 }).padStart(10, " ") || 'N/A';
        const formattedN = data.seconds_n[date]?.toLocaleString('en-US', { maximumFractionDigits: 0 }).padStart(10, " ") || 'N/A';
    
        tableString += `| ${date} | ${formattedSeconds} | ${formattedBytes} | ${formattedMB} | ${formattedN} |\n`;
      }
    
      setCustomerConsumption(tableString);
    
      return null;
    }
    
    runFunction() ;
  }, [isAuthenticated] ) ;

  useEffect(() => {
    async function checkAuth() {
      try {
        await Auth.currentAuthenticatedUser();
        setIsAuthenticated(true);
      } catch (err) {
        setIsAuthenticated(false);
      }
    }

    checkAuth();
  }, []);

  async function deleteUser() {
    try {
      // console.log('About to deleteUser()');
      const csb = cancelSubscription();

      const deleteAllGraphs = async () => {
        // console.log('Deleting graphs');
        let dd = 1000 ;
        for (let i = 1; i <= 10; i++) {
          if(i === 10) {
            console.log('Unable to delete graphs!');
            updateInfoBarError('Unable to delete graphs!');
            return ;
          }

          const graphs = await listGraphs() ;

          graphs.forEach(async (graph) => {
            // console.log("Deleting graph", graph.graphID) ;
            await deleteGraph(graph.graphID) ;
            // console.log("Graph deleted", graph.projectID) ;
          }) ;
    
          await delay(dd) ;
          dd = dd * 2 ;

          if( (await listGraphs()).length < 1 ) {
            // console.log("All graphs deleted") ;
            return ;
          }
        }
      }
      const grp = deleteAllGraphs() ;

      const deleteAllDatasets = async () => {
        // console.log('Deleting datasets');

        let dd = 1000 ;
        for (let i = 1; i <= 10; i++) {
          if(i === 10) {
            console.log('Unable to delete dataset!');
            updateInfoBarError('Unable to delete dataset!');
            return ;
          }

          const datasets = await getDatasets() ;

          datasets.forEach(async (dataset) => {
            // console.log("Deleting dataset", dataset.projectID) ;
            await deleteProjectEnvelopeAndContent(dataset.projectID) ;
            // console.log("Dataset deleted", dataset.projectID) ;
          }) ;
    
          await delay(dd) ;
          dd = dd * 2 ;

          if( (await getDatasets()).length < 1 ) {
            // console.log("All datasets deleted") ;
            return ;
          }
        }
      }
      const ds = deleteAllDatasets() ;
      await ds ;
      await grp ;
      await csb ;

      const result = await Auth.deleteUser();
      // console.log(result);
    } catch (error) {
      console.log('Error deleting user', error);
      updateInfoBarError('Error deleting user:' + error);
    }
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // console.log(textInput);
    if(textInput === "permanently delete") {
      // console.log("Deleting all");
      deleteUser();
    } else {
      updateInfoBarError('Wrong message. Not deleting anything.');
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTextInput(event.target.value);
  };
 
  useEffect(() => { // runStripeIDandProduct
    if(!isAuthenticated) { 
      return ; 
    }

    async function computeSHA256(str: string) {
      // Convert the string into a Uint8Array containing the UTF-8 encoding of the string
      const encoder = new TextEncoder();
      const data = encoder.encode(str);
    
      // Compute the SHA-256 hash of the data
      const hashBuffer = await crypto.subtle.digest('SHA-256', data);
    
      // Convert the hash ArrayBuffer to a hexadecimal string
      const hashArray = Array.from(new Uint8Array(hashBuffer));
      const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
    
      return hashHex;
    }

    const runCustomerSessionClientSecret = async () => {
      const { customer_id , client_secret, email } = await getCustomerSessionClientSecret();
      setCustomerEmail(email);
      const data = await computeSHA256(email);
      // console.log('data', data);
      setClientReferenceId(data);
      setCustomerSessionClientSecret(client_secret);
      // console.log("result", customer_id , client_secret, email );
    }

    const formatUnixTimestamp = (unixTimestamp: number): string => {
      const date = new Date(unixTimestamp * 1000);

      // Format the year, month, and day in UTC
      const year = date.getUTCFullYear();
      const month = date.getUTCMonth() + 1; // getUTCMonth() returns month from 0-11
      const day = date.getUTCDate();
  
      // Format the hours and minutes in UTC
      const hours = date.getUTCHours();
      const minutes = date.getUTCMinutes();
  
      // Pad the month, day, hours and minutes with zero if they are less than 10
      const formattedDate = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
      const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
  
      // Combine and return the formatted date and time in UTC
      return `${formattedDate} ${formattedTime} UTC`;
    }

    const runStripeIDandProduct = async () => {
      const { customer_id , product, email, start, end, active } = await getStripeIDandProduct();
      // console.log({ customer_id , product, email, start, end, active })
      setCustomerProductActive(active ? "Active" : "Not active") ;
      // setCustomerEmail(email);
      const data = await computeSHA256(email);
      // console.log('data', data);
      setClientReferenceId(data);
      // console.log("result", customer_id , product, email );

      if(!product) {
        setCustomerProduct("None") ;
      } else {
        setCustomerProduct(product) ;
      }

      setCustomerProductStart(formatUnixTimestamp(start)) ;
      setCustomerProductEnd(formatUnixTimestamp(end)) ;
    }

    runCustomerSessionClientSecret() ;
    runStripeIDandProduct() ;
  }, [isAuthenticated]);


  const StripePricingTable = () => {
    useEffect(() => {
      const scriptSrc = 'https://js.stripe.com/v3/pricing-table.js';
      const scriptId = 'stripe-pricing-table-script';
  
      loadScript(scriptSrc, scriptId)
        .then(() => {
          // console.log('Stripe Pricing Table script loaded successfully.');
        })
        .catch((error) => {
          console.error('Failed to load the Stripe Pricing Table script:', error);
        });
    }, [isAuthenticated, customerEmail, customerSessionClientSecret]);
  
    // console.log( "***",{pricingTableId},{publishableKey},{customerEmail},{customerSessionClientSecret} ) ;

    if(!isAuthenticated) { 
      return (<></>); 
    }

    if((!customerEmail) || (!customerSessionClientSecret)) { 
      return (<></>); 
    }
    

  // https://dominiksumer.com/blog/stripe-checkout-eu-vat
  // https://medium.com/slight-pause/how-to-setup-saas-subscriptions-correctly-to-charge-vat-in-europe-d75d857b5d01
//  client-reference-id={'123456asdasdf'}
// https://ec.europa.eu/taxation_customs/vies/#/technical-information
// https://docs.stripe.com/tax/calculating


    if(customerSessionClientSecret === "None") {
      return (
        <stripe-pricing-table
          pricing-table-id={pricingTableId}
          publishable-key={publishableKey}
          customer-email={customerEmail}
          client-reference-id={clientReferenceId}
          />
      );  
    } else {
      return (
        <stripe-pricing-table
          pricing-table-id={pricingTableId}
          publishable-key={publishableKey}
          customer-session-client-secret={customerSessionClientSecret}
          client-reference-id={clientReferenceId}
          />
      );
    }
  };

return(
  // https://ui.docs.amplify.aws/react/connected-components/authenticator/customization
  // https://ui.docs.amplify.aws/react/connected-components/authenticator/customization#css-style
  // https://ui.docs.amplify.aws/react/theming/css-variables
  <>
  <Authenticator className='log__Authenticator'>
     {({ signOut, user }) => {
      return(<>
        <div className='log__user'>
          <h1>2minlog</h1>
          <p>Welcome, {user?.attributes?.email}!</p>
        </div>
          <div className='log__CTAbtn1'><Link to={`/`}><p className='log__CTAbtn1 log__CTAbtn-p1'>Go to home page</p></Link></div>
          <div className='log__CTAbtn1'><Link to={`/projects`}><p className='log__CTAbtn1 log__CTAbtn-p1'>Go to projects</p></Link></div>
          <div className='log__CTAbtn2'><p onClick={signOut} className='log__CTAbtn2 log__CTAbtn-p2'>Logout</p></div>
          <div className='log__CTAbtn3'><p onClick={() => setPressedDelete(true)} className='log__CTAbtn3 log__CTAbtn-p3'>Delete my account</p></div>
            {pressedDelete?(<div className='log__Authenticator_delete'>
              <div className='log__Authenticator_delete_text_x' onClick={
                () => setPressedDelete(false)
                }><RiCloseLine/></div>
              <div className='log__Authenticator_delete_text'>
                Are you sure to delete the account, associated payments profile and all the data? 
              Cannot be undone! If you are sure, type "<i>permanently delete</i>" below.
              </div>
              <form className='log__Authenticator_delete_text' onSubmit={handleSubmit}>
                <label>
                <input type="text" name="input" onChange={handleChange}/>
                </label>
                <input type="submit" value="Delete"/>
                </form>
              </div>):(<></>)}
          <div className='log__StripePricingTable'>
          <p>Products and pricing (7 days free trial):</p>
            <ul className="log__user">
                <li className="log__user">Silver - $25/year (+applicable taxes)</li>
                <ul className="log__userA">
                  <li className="log__userA">Maximum 45 seconds of processing time per hour</li>
                  <li className="log__userA">Maximum 35 MB of data download per hour</li>
                </ul>
                <li className="log__user">Gold - $45/year (+applicable taxes)</li>
                <ul className="log__userA">
                  <li className="log__userA">Maximum 90 seconds of processing time per hour</li>
                  <li className="log__userA">Maximum 70 MB of data download per hour</li>
                </ul>
                <li className="log__user">Diamond - $125/year (+applicable taxes)</li>
                <ul className="log__userA">
                  <li className="log__userA">Maximum 250 seconds of processing time per hour</li>
                  <li className="log__userA">Maximum 200 MB of data download per hour</li>
                </ul>
                <li className="log__user">When you exceed the limit, the data download and image generation will stop working. Data upload will still work.</li>
                <li className="log__user">We charge the annual fee upfront.</li>
            </ul>
            <br/>

            {(customerProductActive!=="Active")&&(<>
              <p>Buy your subscription & start the free trial now:</p>
                <StripePricingTable/>
              <br/>
              </>
            )
            }

            <p>To manage your subscription, billing, and invoicing, visit the{' '}
            <a href={`${customerPortalURL}?prefilled_email=${user?.attributes?.email || ''}`}>Customer portal</a>.
            </p>
            <br/>

            <p>
            Status:  
            </p>
            <code>
              <pre className="log__user-consumptiontable">
              - Your current subscription: {customerProduct+"\n"}
              - Current substription status: {customerProductActive+"\n"}
              - Current substription period start: {customerProductStart+"\n"}
              - Current substription period end: {customerProductEnd+"\n"}
              </pre>
            </code>

            <br/>
            <p>
            Here is your daily consumption:
            </p>
            <code>
              <pre className="log__user-consumptiontable">
              {customerConsumption}
              </pre>
            </code>
          </div>
        </>
      );
      }
    }
  </Authenticator>
  {!isAuthenticated?(<Link to={`/`} className='log__Authenticator_rtn_link'>Return back to the home page</Link>):(<></>)}
  </>
  );
}

export default User;