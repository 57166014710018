// import React from 'react';
import { CTAbtn } from '../../components';
import './ctabox.css';


const CTAbox = () => (
  <div className='log__CTAbox'>
    <div className='log__CTAbox-left'>
      <h1>Try our services now!</h1>
      <p className='log__CTAbox-txt'>Everything you need to save, process and plot your data.</p>
    </div>
    <div className='log__CTAbox-right'>
      <CTAbtn>
        <p>Start free trial now!</p>
      </CTAbtn>
    </div>
  </div>
);

export default CTAbox;