
/*
Usage plain javascript:
  updateInfoBar('getNumberOfRecords');

Usage React:
{
const updateInfoBar = useInfoBarUpdater();

return(
        <button onClick={() => updateInfoBar('New InfoBar String')}>
            Update InfoBar
        </button>
    );
}
*/

import './infobar.css' ;

import React, { useState, createContext, useContext, ReactNode, useEffect } from 'react';

// Define a type for the context state
interface InfoBarContextState {
  infoBarString: string;
}

// Create a context with an initial undefined value, but the context will actually be of type InfoBarContextState
const InfoBarContext = createContext<InfoBarContextState | undefined>(undefined);

// Define the type for the props of the provider component
interface InfoBarContextProviderProps {
  children: ReactNode;
}

// Context provider component
const InfoBarContextProvider: React.FC<InfoBarContextProviderProps> = ({ children }) => {
  const [infoBarString, setInfoBarString] = useState<string>('');

  useEffect(() => {
    // Type the event as CustomEvent<string>
    const handleUpdateInfoBar = (event: CustomEvent<string>) => {
      setInfoBarString(event.detail);
      setTimeout(setInfoBarString,5000,'');
    };

    window.addEventListener('update-info-bar', handleUpdateInfoBar as EventListener);

    return () => {
      window.removeEventListener('update-info-bar', handleUpdateInfoBar as EventListener);
    };
  }, []);

  return (
    <InfoBarContext.Provider value={{ infoBarString }}>
      {children}
    </InfoBarContext.Provider>
  );
};


// Custom hook for React components
const useInfoBarUpdater = () => {
  return (newString: string) => updateInfoBar(newString);
};

// Function to update the info bar, can be used in both React and non-React code
function updateInfoBar(newString: string) {
  const event = new CustomEvent<string>('update-info-bar', { detail: 'XXX' + newString });
  window.dispatchEvent(event);
}

function updateInfoBarError(newString: string) {
  console.log('updateInfoBarError', { detail: "Error: " + newString });
  const event = new CustomEvent<string>('update-info-bar', { detail: "Error: " + newString });
  window.dispatchEvent(event);
}

function updateInfoBarInfo(newString: string) {
  console.log('updateInfoBarInfo', { detail: "Info: " + newString });
  const event = new CustomEvent<string>('update-info-bar', { detail: "Info: " + newString });
  window.dispatchEvent(event);
}


////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////
// Main InfoBar component
const InfoBar: React.FC = () => {
  const context = useContext(InfoBarContext);
  if (!context) {
    throw new Error('InfoBar must be used within an InfoBarContextProvider');
  }
  const { infoBarString } = context;
  console.log('IB', infoBarString.slice(0,6));
 
  return (
    infoBarString ?
      ( infoBarString.slice(0,6) === "Error:" ?
      (
        <div className="log__infobar--container_error">
          <p>{infoBarString}</p>
        </div>
      ) : (
        <div className="log__infobar--container_info">
          <p>{infoBarString}</p>
        </div>
      )
    ) : null
  );
};

export { InfoBarContextProvider, useInfoBarUpdater, InfoBar, updateInfoBar, updateInfoBarError, updateInfoBarInfo };
