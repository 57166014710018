export const GRAPHQL_API_URI = 'https://w2jfgyfwzzdbnap7awjf2nmv3e.appsync-api.us-east-1.amazonaws.com/graphql'
export const getImgURL = "https://api.2minlog.com/img" ;
export const defaultCode = "https://raw.githubusercontent.com/2minlog/2minlog-examples/main/00-default_code/00_hello_world.py" ;

// // Real
export const pricingTableId="prctbl_1PLVFcFg2fe9vbhHaDdPK6u9";
export const publishableKey="pk_live_51OSexAFg2fe9vbhHsQ2AevN2LQaaAxhX8NpgV5efN3jySYGP1Xg6CZZhw00RKfLT4XXKlUv1f0CM1Xxl1AOVIJ5s00oPEAnCU8";
export const customerPortalURL="https://billing.stripe.com/p/login/8wM7tF0mjb790XC7ss";

// Testing
// export const pricingTableId="prctbl_1PHta2Fg2fe9vbhHawalsTHd";
// export const publishableKey="pk_test_51OSexAFg2fe9vbhHZsAUYqk9gUlQVmVe4mcyrb2IwnPodqLxqIfGGbteND11nDbC3uHaIBW6JwaSCvoIXYzY2i9D00cVlCSenA";
// export const customerPortalURL="https://billing.stripe.com/p/login/test_5kA5krgKL2PR360eUU";

export const oauthDomain="auth.2minlog.com" ;

export const maxRecordsInDataset=100000 ;

export const initialMaxRecordsDownloads = 2880 ; // 48 hours of records per 1 minute per record
export const initialSecondsBack = 10 * 24 * 3600 ;
