import React from 'react';
import { useState, useEffect} from 'react';
import { updateInfoBarError } from '../../pages'

// https://docs.amplify.aws/lib/auth/getting-started/q/platform/js/
// https://docs.amplify.aws/lib/auth/getting-started/q/platform/js/#create-authentication-service

// Hosted UI Endpoint: https://2minloggui61a09399-61a09399-dev.auth.us-east-1.amazoncognito.com/
// Test Your Hosted UI Endpoint: https://2minloggui61a09399-61a09399-dev.auth.us-east-1.amazoncognito.com/login?response_type=code&client_id=4212fe7vapfnfpdeois1cjrmas&redirect_uri=https://2minlog.com/

import { Amplify } from 'aws-amplify';

import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { Hub } from 'aws-amplify';
import awsConfig from '../../aws-exports';
import './authentication.css';
import { Auth } from 'aws-amplify';

import { Link } from "react-router-dom";

// https://docs.amplify.aws/lib/auth/social/q/platform/js/#configure-auth-category
const isLocalhost = Boolean(
  window.location.hostname === "localhost" ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === "[::1]" ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

// Assuming you have two redirect URIs, and the first is for localhost and second is for production
const [
  localRedirectSignIn,
  productionRedirectSignIn,
] = awsConfig.oauth.redirectSignIn.split(",");

const [
  localRedirectSignOut,
  productionRedirectSignOut,
] = awsConfig.oauth.redirectSignOut.split(",");

const updatedAwsConfig = {
  ...awsConfig,
  oauth: {
    ...awsConfig.oauth,
    redirectSignIn: isLocalhost ? localRedirectSignIn : productionRedirectSignIn,
    redirectSignOut: isLocalhost ? localRedirectSignOut : productionRedirectSignOut,
  }
}

// https://watilde.medium.com/how-to-use-a-custom-domain-of-cognito-hosted-ui-with-amplify-de6b772281ba
// Refs: https://github.com/aws-amplify/amplify-cli/issues/1880
updatedAwsConfig.oauth.domain = "auth.2minlog.com";

Hub.listen('auth', (data) => {
  // console.log('XX8989', data);
  switch (data.payload.event) {
    case 'signIn':
        console.log('user signed in');
        break;
    case 'signUp':
        console.log('user signed up');
        break;
    case 'signOut':
        console.log('user signed out');
        break;
    case 'signIn_failure':
        console.log('user sign in failed');
        const regex1a = new RegExp("\\+", 'g');
        const regex2a = new RegExp("PreSignUp failed with error ");
        const x1 = JSON.stringify(data.payload?.data?.message)
          .replace(regex1a, " ")
          .replace(regex2a, "");

        updateInfoBarError('User sign-in failed: '+ x1);
        setTimeout(() => {
          updateInfoBarError('User sign-in failed: '+ x1);
        }, 1000);
        console.log("user sign in failed - code o2uyg387g", JSON.stringify(data));
        break;
    case 'configured':
        console.log('the Auth module is configured');
        break;
    case 'customState_failure':
      console.log('customState_failure');
      const regex1b = new RegExp("\\+", 'g');
      const regex2b = new RegExp("PreSignUp failed with error ");
      const x2 = JSON.stringify(data.payload?.data?.message)
        .replace(regex1b, " ")
        .replace(regex2b, "");

      updateInfoBarError('User sign-in failed: '+ x2);
      setTimeout(() => {
        updateInfoBarError('User sign-in failed: '+ x2);
      }, 1000);
      console.log("customState_failure - code poiuhpo92g22", JSON.stringify(data));
      break;
  }
});

try {
  Amplify.configure(updatedAwsConfig);
} catch(err) {
  console.log("ERR - biuhg287g3 Amplify.configure(updatedAwsConfig)", err);
}


// https://docs.amplify.aws/lib/auth/social/q/platform/js/#configure-auth-category
function Authentication() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userEmail, setUserEmail] = useState('');

  useEffect(() => {
    async function checkAuth() {
      try {
        await Auth.currentAuthenticatedUser();
        setIsAuthenticated(true);
      } catch (err) {
        setIsAuthenticated(false);
      }
    }

    checkAuth().catch(console.error);
  }, []);

  useEffect(() => {
    async function getUserAttributes() {
      try {
        const user = await Auth.currentAuthenticatedUser();
        const attributes = await Auth.userAttributes(user);
        const emailAttribute = attributes.find(attr => attr.Name === 'email');
        if (emailAttribute) {
          setUserEmail(emailAttribute.Value);
        }
      } catch (err) {
        console.error('Error getting user attributes:', err);
        updateInfoBarError('Authentication getting user attributes:' + err)
      }
    }

    getUserAttributes().catch(console.error);
  }, []);

  return (
    <div>
      {/*
      <div>
        {isAuthenticated ? 
        (<p>You are signed in as {userEmail}</p>) : 
        (<></>) 
        }
      </div>
      */ }
    <Authenticator className='log__Authenticator'>
      {/*({ signOut, user }) => (
        <main>
          <h1>Hello {user?.attributes?.email}
          </h1>
          <button onClick={signOut}>Sign out</button>
          <p>Go to <Link to={`/project`}>Projects.</Link></p>
        </main>
      )*/}
    </Authenticator>

    </div>
  );
}

export default Authentication;
