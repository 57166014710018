// import React from 'react';
import { useState, useEffect, useCallback } from "react";
import './landing.css';
import { Navbar, Intro, Examples, CTAbox, Footer } from './../../containers';
import {IntroHeader, IntroSubHeader, IntroText, IntroCTAbtn} from './../../containers';

// import { useQuery } from '@apollo/client';
import { Auth } from 'aws-amplify';

import backgroundImage from './../../assets/massimo-botturi-zFYUsLk_50Y-unsplash.jpg';
// import { error } from "console";

const Landing = () => {
  // Handle # links - e.g. /#about when going from another page
  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      const element = document.querySelector(hash);
      if (element) element.scrollIntoView();
    }
  }, []);

  return (
    <>
      <div className="log__bcgimg">
        <img alt='Background' src={backgroundImage} width="541px" />
      </div>
      <div className="log__bcgimg-full_width">
        <img alt='Background' src={backgroundImage} width="100%" />
      </div>


      <Navbar />
      <Intro>
        <IntroHeader>
          2minlog
        </IntroHeader>

        <IntroSubHeader>
        Connect source of data and plot awesome graphs in 2 minutes.
        </IntroSubHeader>

        <IntroCTAbtn/>

        <IntroText>
        Seamless data flow from any source - IoT, Arduino, RPi, weather stations, mobile, network devices 
        and more. Securely store and visualize it with Python-powered graphs. Access your system 
        from anywhere in the world!
        </IntroText>
      </Intro>
      <Examples />
      <CTAbox />
      <Footer />


    </>
  );
}

export default Landing;
