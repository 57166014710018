import { Landing, Projects, User, LoginError, InfoBarContextProvider, InfoBar } from './pages';

import { createBrowserRouter, RouterProvider, Link } from "react-router-dom";

import { useEffect } from "react";

const LoadingPage = () => {

  useEffect(() => {
    const handleLoad = async () => {
      const unixMillisecondsNow = Date.now();
      const totalTime = unixMillisecondsNow - window.performance.timing.connectStart

      const platform = navigator.platform;
      const userAgent = navigator.userAgent;

      console.log("totalTime:", totalTime) ;

      const url = "https://api.2minlog.com/log" ;
      const passwd='SEC-aeaab34f-fc80-49a5-8ab3-145695fddccb' ;

      await fetch(`${url}?datasetSecret=${passwd}&totalTime=${totalTime}&platform="${platform}"&userAgent="${userAgent}"`);
    };

    // Listen for the window 'load' event
    window.addEventListener('load', handleLoad);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener('load', handleLoad);
    };
  }, []);

}

const ErrorPG = () => { return (<>Page not found. Go <Link to={`/`}>home.</Link></>) }

const router = createBrowserRouter([
  {
    path: "/",
    element: <Landing/>,
    errorElement: <ErrorPG />
  },
  {
    path: "/projects",
    element: <Projects />,
  },
  {
    path: "/user",
    element: <User/>,
  },
  {
    path: "/error",
    element: <LoginError />,
  },
  {
    path: "*",
    element: <ErrorPG />
  },
  ]);

function App(props: any) {
  LoadingPage();

  return (
    <div className="body">
      <div className="App">
        <InfoBarContextProvider>
          <InfoBar/>
          <RouterProvider router={router} />
        </InfoBarContextProvider>
      </div>
    </div>
  );
}

export default App;
