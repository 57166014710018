import React from 'react';
import './intro.css';
import { CTAbtn } from '../../components';

type TextComponentProps = {
  children: React.ReactNode;
};

export const IntroHeader: React.FC<TextComponentProps> = ({children}) => (
  <div className='log__intro'>
    <h1>
      {children}
    </h1>
  </div>
);

export const IntroSubHeader: React.FC<TextComponentProps> = ({children}) => (
  <div className='log__intro'>
    <p>
      {children}
    </p>
  </div>
);

export const IntroText: React.FC<TextComponentProps> = ({children}) => (
  <div className='log__intro-text'>
    <p>
      {children}
    </p>
  </div>
);

export const IntroCTAbtn = () => (
  <div className='log__intro-CTAspace'>
      <CTAbtn>
        <p>Start free trial now!</p>
      </CTAbtn>
  </div>
);


const Intro: React.FC<TextComponentProps> = ({children}) => {
return(
    <div id="intro" className='log__intro'>
      {children}
    </div>
  );
}

export default Intro;