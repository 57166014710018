import { useState, useRef } from 'react';
import './displaycsv.css';


type DisplayCSVProps = {
    csvString: string;
};

type ParsedCsvData = {
    [key: string]: string;
  };
  
const DisplayCSV: React.FC<DisplayCSVProps> = ({ csvString }) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const [isDragging, setIsDragging] = useState(false);
    const [startX, setStartX] = useState(0);
    const [scrollLeft, setScrollLeft] = useState(0);

    const onMouseDown = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (containerRef.current) {
            setIsDragging(true);
            setStartX(e.pageX - containerRef.current.offsetLeft);
            setScrollLeft(containerRef.current.scrollLeft);
        }
    };

    const onMouseLeave = () => {
        setIsDragging(false);
    };

    const onMouseUp = () => {
        setIsDragging(false);
    };

    const onMouseMove = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (!isDragging) return;
        e.preventDefault();
        if (containerRef.current) {
            const x = e.pageX - containerRef.current.offsetLeft;
            const walk = (x - startX) * 2; // Adjust the scroll speed as needed
            containerRef.current.scrollLeft = scrollLeft - walk;
        }
    };

    // const fullDataSet: string = 'name,age\nAlice,24\nBob,30';

    // Function to parse CSV data
    const parseCsvData = (): { headers: string[], data: ParsedCsvData[] } => {
        const rows = csvString.split('\n');
        const headers = rows[0].split(',').map(header => header.trim());
        const data = rows.slice(1).map(row => {
        const values = row.split(',');
        return headers.reduce<ParsedCsvData>((object, header, index) => {
            object[header] = values[index].trim();
            return object;
        }, {});
        });
        return { headers, data };
    };

    // Function to render CSV data as a table
    const renderCsvData = (): JSX.Element => {
        const { headers, data } = parseCsvData();

        return (
        <table className="log__displaycsv--table">
            <thead>
                <tr>
                    {headers.map((header, index) => (
                    <th key={index}>{header}</th>
                    ))}
                </tr>
            </thead>
            <tbody>
            {data.map((row, rowIndex) => (
                <tr key={rowIndex}>
                {headers.map((header, index) => (
                    <td key={index}>{row[header]}</td>
                ))}
                </tr>
            ))}
            </tbody>
        </table>
        );
    };

    return (
        <div
        ref={containerRef}
        onMouseDown={onMouseDown}
        onMouseLeave={onMouseLeave}
        onMouseUp={onMouseUp}
        onMouseMove={onMouseMove}
        className="log__displaycsv"
        >
        <div className="log__displaycsv--wide">
            {renderCsvData()}
        </div>
        </div>
    );
};

export default DisplayCSV;