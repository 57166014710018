import React, { useState, useEffect, useRef } from 'react';
import './imageloader.css';

interface ImageLoaderProps {
    src: string;
    alt: string;
    className?: string; // Optional className prop
}

const ImageLoader: React.FC<ImageLoaderProps> = ({ src, alt, className }) => {
    const [loaded, setLoaded] = useState<boolean>(false);
    const [error, setError] = useState<string>('');
    const [imageSize, setImageSize] = useState({ width: 0, height: 0 });
    const imageRef = useRef<HTMLImageElement>(null);
    const [text, setText] = useState<string>('');
    const [htmlContent, setHtmlContent] = useState<string>(''); // State to store HTML content

    useEffect(() => {
        const loadImage = async () => {
            try {
                const response = await fetch(src);
                // console.log("response", response);
    
                const contentType = response.headers.get("Content-Type");
                // console.log("contentType", contentType);
    
                if (!response.ok) {
                    const errorText = await response.text();
                    throw new Error(`Unable to load image: ${response.status} ${errorText}`);
                }
    
                if (contentType && contentType.startsWith('image')) {
                    const blob = await response.blob();
                    if (imageRef.current) {
                        imageRef.current.src = URL.createObjectURL(blob);
                    }
                    setText('');
                    setHtmlContent('');
                } else if (contentType && contentType.startsWith('text/html')) {
                    // Handle HTML content
                    const html = await response.text();
                    // console.log("HTML content received:", html);
                    setHtmlContent(html); // Save the HTML content
                    setText('');
                } else {
                    // Handle non-image and non-HTML content types
                    const text = await response.text();
                    // console.log("Non-image content received:", text);
                    setText(text);
                    setHtmlContent('');
                }
            } catch (error) {
                setText('');
                setHtmlContent('');
                if (error instanceof Error) {
                    setError(error.message);
                } else {
                    setError("An unknown error occurred");
                }
            }
        };
    
        loadImage();
    }, [src]);
    
    const handleImageLoaded = () => {
        setLoaded(true);
        if (imageRef.current) {
            const { width, height } = imageRef.current;
            setImageSize({ width, height });
        }
    };

    const handleImageError = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
        if (!error) { // Only update error state if not already set by fetch
            setError('Error loading image');
        }
    };

    if (error) {
        return (
            <div className={"log__imageloader--error " + className}>
                <pre>{error}</pre>
            </div>
        );
    }

    if (htmlContent ) {
        return (
            <div className={"log__imageloader--html " + className} 
                dangerouslySetInnerHTML={{ __html: htmlContent }} />
        );
    }

    if (text ) {
        return (
            <div className={"log__imageloader--text " + className}>
                <pre>{text}</pre>
            </div>
        );
    }

    return (
        <>
            {text === '' && !loaded && (
                <div className={"log__imageloader--loading " + className}>
                    <p>Loading...</p>
                </div>
            )}
            <img 
                alt={alt} 
                className={className} 
                onLoad={handleImageLoaded}
                onError={handleImageError}
                ref={imageRef}
                style={{ display: loaded ? 'block' : 'none' }}
            />
        </>
    );
};

export default ImageLoader;
