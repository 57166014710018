import React, { useState } from 'react';
import { TfiFiles, TfiCheck } from 'react-icons/tfi'; // Import the icons you need

interface CopyStringProps {
  textToCopy: string;
  children: React.ReactNode;
  className?: string;
}

const CopyString: React.FC<CopyStringProps> = ({ textToCopy, children, className}) => {
  const [copySuccess, setCopySuccess] = useState(false);

  const handleCopyClick = async (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();

    try {
      await navigator.clipboard.writeText(textToCopy);
      setCopySuccess(true);
      setTimeout(() => setCopySuccess(false), 1000); // Reset icon after 1 second
    } catch (err) {
      console.error('Failed to copy text: ', err);
      setCopySuccess(false);
    }
  };

  return (
    <div onClick={handleCopyClick} style={{ cursor: 'pointer' }} className={className}>
      {children}
      {copySuccess ? <TfiCheck style={{ color: 'green' }} /> : <TfiFiles />}
    </div>
  );
};

export default CopyString;
