import React from 'react';
import './datasetcard.css';
// import { RxReload } from "react-icons/rx";
import { TfiReload, TfiSettings, TfiAngleDown, TfiAngleUp, TfiClose } from "react-icons/tfi";
import { useState, useEffect, useRef } from 'react';
import {
  getNumberOfRecords, deleteProjectEnvelopeAndContent,
  deleteProjectContent, renameProject, setMaxRecords, resetSecret, resetPublic, getProjectDataAsCSV
} from '../../middleware/apollo';
import { CopyString, DisplayCSV } from './../../components';


import { maxRecordsInDataset } from '../../config'
import { updateInfoBarError, updateInfoBarInfo } from '../../pages'


interface Project {
  projectID: string;
  email: string;
  time: string;
  projectSecret: string;
  projectPublic: string;
  name: string;
  payLoad: string | null;
}

type DatasetCardProps = {
  dataset: Project;
};

/////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////
//// Content of Last 20 Records
interface ContentOfAllRecordsProps {
  autoRefresh: string;
  projectPublic: string; // Modify this based on the actual structure of your dataset object
}

const ContentOfAllRecords: React.FC<ContentOfAllRecordsProps> = ({ autoRefresh, projectPublic }) => {
  const [fullDataSet, setFullDataSet] = useState("Loading...");
  const autoRefreshId = useRef<number>(0);

  const fetchContentOfLastRecord = async () => {
    // console.log("fetchNoOfRecords", id, (new Date()).toISOString());
    try {
      const data = await getProjectDataAsCSV(projectPublic, 20);
      if (JSON.stringify(data) !== JSON.stringify(fullDataSet))
        setFullDataSet(data);
    } catch (error) {
      setFullDataSet("Error.");
      console.error("ERR - code li98y2ug39:", error);
    }
  };

  const updateAutoRefresh = () => {
    if (autoRefreshId.current > 0) {
      clearInterval(autoRefreshId.current);
      autoRefreshId.current = 0;
    }

    const interval = parseInt(autoRefresh);
    if (!isNaN(interval) && interval > 0) {
      const delay = 1000 * interval;
      if (delay > 0) {
        const id = setInterval(fetchContentOfLastRecord, delay) as unknown as number;
        autoRefreshId.current = id;
      }
    } else {
      autoRefreshId.current = 0;
    }
  };

  useEffect(
    () => {
      updateAutoRefresh();

      return (() => {
        if (autoRefreshId.current > 0) {
          clearInterval(autoRefreshId.current);
          autoRefreshId.current = 0;
        }
      });

    }, [autoRefresh]);


  useEffect(() => {
    fetchContentOfLastRecord();
  }, [projectPublic]);

  return (<DisplayCSV csvString={fullDataSet} />);
}

/////////////////////////////////////////////////////////////////////////////////////////
//// First Record and Its time
const fetchFirstAndTime = async (projectPublic: string) => {
  const extractTimeValue = (csvString: string): string | null => {
    // Split the CSV string into rows
    const rows = csvString.split('\n');

    // Check if there are rows
    if (rows.length < 2) {
      return null; // No data rows present
    }

    // Split the header row into columns and find the index of 'time'
    const headers = rows[0].split(',');
    const timeIndex = headers.indexOf('timestamp');

    // Check if 'time' column is present
    if (timeIndex === -1) {
      return null; // 'time' column not found
    }

    // Split the first data row into columns and extract the value of 'time'
    const firstDataRow = rows[1].split(',');
    return firstDataRow[timeIndex];
  }

  const csvToKeyValue = (csvString: string): Record<string, string> | null => {
    // Split the CSV string into rows
    const rows = csvString.split('\n');

    // Check if there are enough rows (header and at least one data row)
    if (rows.length < 2) {
      return null; // Not enough data
    }

    // Split the header row and the first data row into columns
    const headers = rows[0].split(',');
    const values = rows[1].split(',');

    // Check for length mismatch between headers and data
    if (headers.length !== values.length) {
      return null; // Mismatch in number of columns
    }

    // Create the key-value object
    const result: Record<string, string> = {};
    for (let i = 0; i < headers.length; i++) {
      result[headers[i]] = values[i];
    }

    return result;
  }

  try {

    const data = await getProjectDataAsCSV(projectPublic, 1);
    let time: string = "Undefined";

    try {
      const timeValue = extractTimeValue(data); // Extract the time value
      if (timeValue) { // Check if the time value is not null
        const date = new Date(timeValue);
        if (isNaN(date.getTime())) {
          time = "N.a.";
        } else {
          // time = date.toLocaleDateString() + " " + date.toLocaleTimeString();
          time = `${date.getFullYear()}-${(date.getMonth()+1).toString().padStart(2, '0')
            }-${date.getDate().toString().padStart(2, '0')} ${date.getHours().toString().padStart(2, '0')
            }:${date.getMinutes().toString().padStart(2, '0')}:${date.getSeconds().toString().padStart(2, '0')}`;
        }
      } else {
        time = "N.a.";
      }
    } catch (err) {
      time = "Error";
    }

    // console.log('data', data);
    const content = JSON.stringify(csvToKeyValue(data), null, " ");
    // console.log('content', content);

    return ({ content: content, time: time });
  } catch (error) {
    console.error("ERR - code liug298t36s00:", error);
    updateInfoBarError('Unable to transform CSV file to display');
    return ({ content: "-", time: "N.a." });
  }
};

/////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////
//// Content of Last Record
interface ContentOfLastRecordProps {
  autoRefresh: string;
  projectPublic: string; // Modify this based on the actual structure of your dataset object
}

const ContentOfLastRecord: React.FC<ContentOfLastRecordProps> = ({ autoRefresh, projectPublic }) => {
  const [contentOfLastRecord, setContentOfLastRecord] = useState("Loading...");
  const autoRefreshId = useRef<number>(0);

  const fetchContentOfLastRecord = async () => {
    try {
      const result = await fetchFirstAndTime(projectPublic);
      if (JSON.stringify(result.content) !== JSON.stringify(autoRefreshId))
        setContentOfLastRecord(result.content); // Update state with the result
    } catch (error) {
      console.error("ERR - code po28dtcbzo3783:", error);
    }
  };

  const updateAutoRefresh = () => {
    if (autoRefreshId.current > 0) {
      clearInterval(autoRefreshId.current);
      autoRefreshId.current = 0;
    }

    const interval = parseInt(autoRefresh);
    if (!isNaN(interval) && interval > 0) {
      const delay = 1000 * interval;
      if (delay > 0) {
        const id = setInterval(fetchContentOfLastRecord, delay) as unknown as number;
        autoRefreshId.current = id;
      }
    } else {
      autoRefreshId.current = 0;
    }
  };

  useEffect(
    () => {
      updateAutoRefresh();

      return (() => {
        if (autoRefreshId.current > 0) {
          clearInterval(autoRefreshId.current);
          autoRefreshId.current = 0;
        }
      });

    }, [autoRefresh]);

  useEffect(() => {
    fetchContentOfLastRecord();
  }, [projectPublic]);

  return (<p>{contentOfLastRecord}</p>);
}


/////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////
//// Time of last record
interface TimeOfLastRecordProps {
  autoRefresh: string;
  projectPublic: string; // Modify this based on the actual structure of your dataset object
}

const TimeOfLastRecord: React.FC<TimeOfLastRecordProps> = ({ autoRefresh, projectPublic }) => {
  const [timeOfLastRecord, setTimeOfLastRecord] = useState("Loading...");
  const autoRefreshId = useRef<number>(0);

  const fetchTimeOfLastRecord = async () => {
    // console.log("fetchNoOfRecords", id, (new Date()).toISOString());
    try {
      const result = await fetchFirstAndTime(projectPublic);
      setTimeOfLastRecord(result.time); // Update state with the result
    } catch (error) {
      console.error("ERR - code iuh92720y38ysa:", error);
    }
  };

  const updateAutoRefresh = () => {
    if (autoRefreshId.current > 0) {
      clearInterval(autoRefreshId.current);
      autoRefreshId.current = 0;
    }

    const interval = parseInt(autoRefresh);
    if (!isNaN(interval) && interval > 0) {
      const delay = 1000 * interval;
      if (delay > 0) {
        const id = setInterval(fetchTimeOfLastRecord, delay) as unknown as number;
        autoRefreshId.current = id;
      }
    } else {
      autoRefreshId.current = 0;
    }
  };

  useEffect(
    () => {
      updateAutoRefresh();

      return (() => {
        if (autoRefreshId.current > 0) {
          clearInterval(autoRefreshId.current);
          autoRefreshId.current = 0;
        }
      });

    }, [autoRefresh]);

  useEffect(() => {
    fetchTimeOfLastRecord();
  }, [projectPublic]);

  return (<p>Last record: {timeOfLastRecord} UTC</p>);
}


/////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////
//// Number of records
interface NumberOfRecordsProps {
  autoRefresh: string;
  projectID: string; // Modify this based on the actual structure of your dataset object
}

const NumberOfRecords: React.FC<NumberOfRecordsProps> = ({ autoRefresh, projectID }) => {
  // console.log("NumberOfRecords1", autoRefresh, projectID);
  const [noOfRecords, setNoOfRecords] = useState("Loading...");
  const autoRefreshId = useRef<number>(0);

  const fetchNoOfRecords = async () => {
    // console.log("fetchNoOfRecords", id, (new Date()).toISOString());
    try {
      // console.log("NumberOfRecords2", autoRefresh, projectID);
      const result = await getNumberOfRecords(projectID);
      if (result !== noOfRecords) {
        // console.log('Received no. of records:',result);
        setNoOfRecords(result); // Update state with the result
      }
    } catch (error) {
      console.error("ERR - code ane0274oja:", error);
    }
  };

  const updateAutoRefresh = () => {
    // console.log("updateAutoRefresh - NumberOfRecords", autoRefresh, id, autoRefreshId);

    if (autoRefreshId.current > 0) {
      // console.log("updateAutoRefresh - clearInterval1", autoRefreshId)
      // console.log('updateAutoRefreshX - clear2', autoRefreshId.current);
      clearInterval(autoRefreshId.current);
      autoRefreshId.current = 0;
    }

    const interval = parseInt(autoRefresh);
    if (!isNaN(interval) && interval > 0) {
      const delay = 1000 * interval;
      if (delay > 0) {
        const id = setInterval(fetchNoOfRecords, delay) as unknown as number;
        autoRefreshId.current = id;
        // console.log('updateAutoRefreshX 1', delay, id);
      }
    } else {
      // console.log('updateAutoRefreshX - clear3', autoRefreshId.current);
      autoRefreshId.current = 0;
    }
  };

  useEffect(
    () => {
      updateAutoRefresh();

      return (() => {
        if (autoRefreshId.current > 0) {
          // console.log('updateAutoRefreshX - clear1', autoRefreshId.current);
          clearInterval(autoRefreshId.current);
          autoRefreshId.current = 0;
        }
      });

    }, [autoRefresh]);

  useEffect(() => {
    // console.log("useEffect []")
    fetchNoOfRecords();
  }, [projectID]);

  return (<p>Records: {noOfRecords}</p>)
}

///////////////////////////////////////////////////////////////////
interface getDatasetPropertyInterface {
  payLoad: string | null;
}

function getDatasetProperty(dataset: getDatasetPropertyInterface,
  propertyName: string, defaultValue: string): string {

  try {
    if (!dataset || typeof dataset.payLoad !== 'string') {
      return defaultValue;
    }

    const parsed = JSON.parse(dataset.payLoad);
    // Check if the required property exists
    if (parsed.hasOwnProperty(propertyName)) {
      // Convert the property value to a string
      return String(parsed[propertyName]);
    } else {
      return defaultValue;
    }
  } catch (e) {
    // In case of an error during parsing, return the default value
    return defaultValue;
  }
}


/////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////
//// DatasetCard

const DatasetCard: React.FC<DatasetCardProps> = ({ dataset }) => {
  // console.log('*** NEW ROUND ***');
  // console.log({ dataset });

  const [settingsAreVisible, setSettingsAreVisible] = useState(false);
  const [shortIsVisible, setShortIsVisible] = useState(true);
  const [displayConfirmationDeleteData, setDisplayConfirmationDeleteData] = useState(false);
  const [displayConfirmationDeleteDataset, setDisplayConfirmationDeleteDataset] = useState(false);
  const [displayThisCard, setDisplayThisCard] = useState(true);
  const [maxRecordsTmp, setMaxRecordsTmp] = useState(getDatasetProperty(dataset, "maxRecords", maxRecordsInDataset.toString()));
  const [autoRefresh, setAutoRefresh] = useState("0");
  const [datasetName, setDatasetName] = useState(dataset.name);
  const [projectPublic, setProjectPublic] = useState(dataset.projectPublic);
  const [projectSecret, setProjectSecret] = useState(dataset.projectSecret);
  const [key, setKey] = useState(0);

  const delayUpdateLocalDataset = () => {
    setKey(prevKey => prevKey + 1);
  }

  const handleClickSettings = () => {
    settingsAreVisible ?
      setSettingsAreVisible(false) :
      setSettingsAreVisible(true);
  }

  const handleClickCloseSettings = () => {
    setSettingsAreVisible(false);
  }

  const handleClickLong = () => {
    if (shortIsVisible) {
      setShortIsVisible(false);
    } else {
      setShortIsVisible(true);
    }
  }

  const showConfirmDeleteData = () => {
    setDisplayConfirmationDeleteData(true);
  }
  const showConfirmDeleteDataset = () => {
    setDisplayConfirmationDeleteDataset(true);
  }

  const hideConfirmDeleteData = () => {
    setDisplayConfirmationDeleteData(false);
  }
  const hideConfirmDeleteDataset = () => {
    setDisplayConfirmationDeleteDataset(false);
  }

  const yesDeleteData = async () => {
    setDisplayConfirmationDeleteData(false);
    deleteProjectContent(dataset.projectID);
    // console.log("Deleting data")
  }

  const yesDeleteDataset = async () => {
    setDisplayConfirmationDeleteDataset(false);
    // console.log("Deleting dataset");
    deleteProjectEnvelopeAndContent(dataset.projectID);
    setDisplayThisCard(false);
  }

  const handleBlurEditName = async (name: string) => {
    if (name.length >= 1) {
      setDatasetName(name);
      renameProject(dataset.projectID, name);
    }
  }

  const handleBlurMaxRecords = async (maxRecords: string) => {
    // console.log("handleBlurMaxRecords", maxRecords, dataset.projectID);
    let num = Number(maxRecords);
    num = Math.min(maxRecordsInDataset, num);
    num = Math.max(0, num);
    setMaxRecordsTmp(num.toString())
    setMaxRecords(dataset.projectID, num);
  };


  const handleBlurAutoRefresh = async (input: string) => {
    // console.log("handleBlurAutoRefresh");

    const interval = parseInt(input);
    if (!isNaN(interval) && interval >= 0) {
      const delay = 1000 * interval;
      if (delay > 0) {
        setAutoRefresh(input);
      } else {
        setAutoRefresh("0")
      }
    }
  };

  const handleResetSecret = async () => {
    const ds = await resetSecret(dataset.projectID);
    setProjectSecret(ds?.projectSecret ?? "");
  };

  const handleResetPublic = async () => {
    const ds = await resetPublic(dataset.projectID);
    setProjectPublic(ds?.projectPublic ?? "");
  };

  /////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////
  //// Settings card
  const DatasetCardSettings = () => {
    return (
      <div className="log__datasetcard--settings">
        <p style={{ textAlign: 'right' }}>
          <TfiClose onClick={handleClickCloseSettings} className="log__datasetcard-link" />
        </p>
        <p>
          Rename dataset: {" "}
          <input className="log__datasetcard--settings--inputfield"
            defaultValue={datasetName}
            onBlur={e => handleBlurEditName(e.target.value)}
            onKeyPress={e => {
              if (e.key === 'Enter') {
                handleBlurEditName((e.target as HTMLInputElement).value);
              }
            }}
          />
        </p>
        <div className="log__datasetcard-link">
          <CopyString textToCopy={projectPublic} className="log__datasetcard-link_p">
            Public: {projectPublic}{' '}
          </CopyString>
        </div>
        <p className="log__datasetcard-link" onClick={handleResetPublic}>
          Reset Public
        </p>
        <div className="log__datasetcard-link">
          <CopyString textToCopy={projectSecret} className="log__datasetcard-link_p">
            Secret: {projectSecret}{' '}
          </CopyString>
        </div>
        <p className="log__datasetcard-link" onClick={handleResetSecret}>
          Reset Secret
        </p>
        <hr /> {/*/////////////////////////////////////////////////////////////////////////////*/}
        <NumberOfRecords autoRefresh={autoRefresh} projectID={dataset.projectID} key={"nr2" + key} />
        <TimeOfLastRecord autoRefresh={autoRefresh} projectPublic={projectPublic} key={"tlr2" + key} />
        <p className="log__datasetcard-link">
          <a href={"https://api.2minlog.com/img?filenameExtension=csv&datasetPublic=" + projectPublic 
          + "&csvFileName=" + datasetName + "&maxRecordsDownloads=2880&secondsBack=172800"}
            download={datasetName + ".csv"}>
            Download dataset as .csv. (Maximum 2,880 records and 172,800 seconds. To increase, modify the URL.)
          </a>
        </p>
        <p>
          See <a href="https://doc.2minlog.com/technical-docs/downloads">documentation</a> for details.
        </p>
        <hr /> {/*/////////////////////////////////////////////////////////////////////////////*/}
        <p className="log__datasetcard-link" onClick={() => { setKey(prevKey => prevKey + 1) }}>
          Refresh data
        </p>
        <p>
          Set autorefresh (s): {" "}
          <input
            className="log__datasetcard--settings--inputfield"
            type="number"
            defaultValue={autoRefresh}
            onBlur={e => handleBlurAutoRefresh(e.target.value)}
            onKeyPress={e => {
              if (e.key === 'Enter') {
                handleBlurAutoRefresh((e.target as HTMLInputElement).value);
              }
            }}
          />
        </p>
        <hr /> {/*/////////////////////////////////////////////////////////////////////////////*/}
        <p>
          Set max records: {" "}
          <input
            className="log__datasetcard--settings--inputfield"
            type="number"
            defaultValue={maxRecordsTmp}
            onBlur={e => handleBlurMaxRecords(e.target.value)}
            onKeyPress={e => {
              if (e.key === 'Enter') {
                handleBlurMaxRecords((e.target as HTMLInputElement).value);
              }
            }}
          />
        </p>
        <hr /> {/*/////////////////////////////////////////////////////////////////////////////*/}
        <p>How to save data to this dataset:</p>
        <p>Option 1:</p>
        <ul>
          <li>URL: https://api.2minlog.com/log?datasetSecret={projectSecret}&key1=value1&key2=value2</li>
          <li className="log__datasetcard-link" onClick={delayUpdateLocalDataset} onContextMenu={delayUpdateLocalDataset}>
            <a href={"https://api.2minlog.com/log?datasetSecret=" + projectSecret + "&T=451"}>
              E.g., click here to save dummy value "T=451" to the DataSet
            </a>
          </li>
        </ul>
        <p>Option 2:</p>
        <ul>
          <li>URL: https://api.2minlog.com/log</li>
          <li>Basic auth - username: 2minlog, passwd: {projectSecret}</li>
          <li>Payload in JSON (can be multilevel)</li>
        </ul>
        <p>Note, "api.2minlog.com" and "api1.2minlog.com" can be used intenchangibly. "api" is preferred over "api1". Port is 443.</p>
        <hr /> {/*/////////////////////////////////////////////////////////////////////////////*/}
        <p className="log__datasetcard-link" onClick={showConfirmDeleteData}>
          Delete data
        </p>
        <p className="log__datasetcard-link" onClick={showConfirmDeleteDataset}>
          Delete the whole dataset
        </p>
      </div>
    );
  }

  /////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////
  //// Main card
  if (!displayThisCard) return (<></>);

  return (
    <div className="log__datasetcard">
      <div className="log__datasetcard-header_box">
        <div className="log__datasetcard-header_box-title">
          {datasetName}
        </div>
        <div className="log__datasetcard-header_box-key_info">
          <NumberOfRecords autoRefresh={autoRefresh} projectID={dataset.projectID} key={"nr1" + key} />
          <TimeOfLastRecord autoRefresh={autoRefresh} projectPublic={projectPublic} key={"tlr1" + key} />
        </div>
        <div className="log__datasetcard-header_box-right_icons">
          <TfiSettings onClick={handleClickSettings} className="log__datasetcard-link" />
          {" "}
          <TfiReload onClick={() => { setKey(prevKey => prevKey + 1) }} className="log__datasetcard-link" />
        </div>
        {settingsAreVisible && (
          <div
            className="log__datasetcard--settings--overlay"
            onClick={handleClickCloseSettings}
          />
        )}
        {settingsAreVisible && (<DatasetCardSettings />)
        }
      </div>
      {shortIsVisible ?
        <div className="log__datasetcard-content_long">
          <ContentOfLastRecord autoRefresh={autoRefresh} projectPublic={projectPublic} key={"clr3" + key} />
        </div> :
        <div className="log__datasetcard-content_long">
          <ContentOfAllRecords autoRefresh={autoRefresh} projectPublic={projectPublic} key={"clrA" + key} />
        </div>
      }
      <>
        {shortIsVisible ?
          <div className="log__datasetcard--icon_unroll_down" onClick={handleClickLong}>
            <TfiAngleDown />
            <TfiAngleDown />
            <TfiAngleDown />
          </div>
          :
          <div className="log__datasetcard--icon_unroll_down" onClick={handleClickLong}>
            <TfiAngleUp />
            <TfiAngleUp />
            <TfiAngleUp />
          </div>
        }
      </>
      {displayConfirmationDeleteData && (
        <div>
          <div className="log__datasetcard--delete_confirmation--overlay" />
          <div className="log__datasetcard--delete_confirmation">
            <div>Delete Data for Dataset {datasetName}?</div>
            <div>(Deletes only the data, keeps the Dataset.)</div>
            <div className="log__datasetcard--delete_confirmation--button-container">
              <button onClick={yesDeleteData} className="log__datasetcard--delete_confirmation--button">Yes</button>
              <button onClick={hideConfirmDeleteData} className="log__datasetcard--delete_confirmation--button">No</button>
            </div>
          </div>
        </div>)
      }
      {displayConfirmationDeleteDataset && (
        <div>
          <div className="log__datasetcard--delete_confirmation--overlay" />
          <div className="log__datasetcard--delete_confirmation">
            <div style={{ color: "red" }}>
              Delete Dataset {datasetName}?
            </div>
            <div>(Deletes both the data and the Dataset.)</div>
            <div className="log__datasetcard--delete_confirmation--button-container">
              <button onClick={yesDeleteDataset} className="log__datasetcard--delete_confirmation--button">Yes</button>
              <button onClick={hideConfirmDeleteDataset} className="log__datasetcard--delete_confirmation--button">No</button>
            </div>
          </div>
        </div>)
      }
    </div>
  );
};

export default DatasetCard;

